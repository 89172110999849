import React, { useRef, useState } from 'react';
import PrismaZoom from 'react-prismazoom';
import SVG from 'react-inlinesvg';
import reactCSS from 'reactcss';
import '../../../../_sass/select-search.scss';
import Hotkeys from 'react-hot-keys';
import $ from 'jquery';
import Select from 'react-select';
import { Rnd } from 'react-rnd';
import Testing from '../../../ui/containers/Testing';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
window.$ = $;
const PhotoFull = (props) => {
  let prismaZoom = useRef('prismaZoom');

  const [hoverIdDamage, sethoverIdDamage] = useState(false);
  const [showModalMaterial, setshowModalMaterial] = useState(false);
  const [chooseMaterial, setchooseMaterial] = useState(false);
  const [listMaterial, setlistMaterial] = useState([
    {
      name: 'Painted',
      enable: false,
      hotKey: 'p',
      icon: 'fas fa-fill-drip',
      url: 'url(../../img/material/painted.svg),auto',
    },
    {
      name: 'Textured Surface',
      enable: false,
      hotKey: 'o',
      icon: 'fas fa-recycle',
      url: 'url(../../img/material/surface.svg),auto',
    },
    {
      name: 'Chrome',
      enable: false,
      hotKey: 'i',
      icon: 'far fa-gem',
      url: 'url(../../img/material/chrome.svg),auto',
    },
    {
      name: 'Steel',
      enable: false,
      hotKey: 'u',
      icon: 'fas fa-dharmachakra',
      url: 'url(../../img/material/steel.svg),auto',
    },
    {
      name: 'Alloy Wheel',
      enable: false,
      hotKey: 'y',
      icon: 'fas fa-drum-steelpan',
      url: 'url(../../img/material/alloy.svg),auto',
    },
    {
      name: 'Plastic Cover',
      enable: false,
      hotKey: 'j',
      icon: 'fas fa-record-vinyl',
      url: 'url(../../img/material/plastic.svg),auto',
    },
    {
      name: 'Steel Wheel',
      enable: false,
      hotKey: 'k',
      icon: 'fas fa-dumbbell',
      url: 'url(../../img/material/steelWheel.svg),auto',
    },
    {
      name: 'Glass',
      enable: false,
      hotKey: 'l',
      icon: 'fas fa-glass-whiskey',
      url: 'url(../../img/material/glass.svg),auto',
    },
  ]);
  const modalMaterial = () => {
    return (
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: '9999',
          borderRadius: '5px',
        }}
      >
        <div
          className="tool-mate"
          style={{
            display: 'flex',
            width: '50%',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#e9e9ed',
            borderRadius: '5px',
          }}
        >
          {listMaterial.map((item, index) => {
            return (
              <div
                key={`mate-${index}`}
                style={{
                  fontSize: '20px',
                  cursor: 'pointer',
                  width: '100%',
                  textAlign: 'center',
                  color: item.enable ? '#fff' : '',
                  background: item.enable ? '#321640' : '',
                  borderRadius: '5px',
                  padding: '5px 0',
                }}
                onClick={() => selectMaterial(item.name, true)}
              >
                <i className={`${item.icon}`}></i>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  const onToggle = () => {
    selectMaterial('');
    setchooseMaterial(false);
  };
  const toolMaterial = () => {
    return (
      <div
        className="tool-mate"
        style={{
          float: 'right',
          display: 'flex',
          flexDirection: 'column',
          width: '3%',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: '8px',
          background: '#e9e9ed',
          borderRadius: '5px',
        }}
      >
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            className="custom-control-input"
            id="ChooseMaterial"
            checked={chooseMaterial}
            onChange={onToggle}
          />
          <label
            className="custom-control-label"
            htmlFor="ChooseMaterial"
          ></label>
        </div>
        {listMaterial.map((item, index) => {
          return (
            <OverlayTrigger
              key={item.name}
              placement="right"
              overlay={
                <Tooltip id={`tooltip-${item.name}`}>
                  <strong>
                    {item.name} ({item.hotKey})
                  </strong>
                </Tooltip>
              }
            >
              <div
                key={`mate-${index}`}
                style={{
                  marginBottom: '8px',
                  fontSize: '22px',
                  cursor: 'pointer',
                  width: '100%',
                  textAlign: 'center',
                  color: item.enable ? '#fff' : '',
                  background: item.enable ? '#321640' : '',
                  borderRadius: '5px',
                  padding: '5px 0',
                }}
                onClick={() => selectMaterial(item.name, true)}
              >
                <i className={`${item.icon}`}></i>
              </div>
            </OverlayTrigger>
          );
        })}
      </div>
    );
  };
  // Damage
  const updateDamage = (e, id) => {
    let { damages } = props;
    if (damages) {
      damages[id] = { ...damages[id], ...e };
      props.clearlistDamages();
      props.updateDamage(damages);
    }
  };
  const updateDamageAgent = (e, id) => {
    let { agentDamages } = props;
    if (agentDamages) {
      agentDamages[id] = {
        ...agentDamages[id],
        ...e,
        admin_response: 'edit',
      };
      props.clearlistDamages();
      props.updateDamagesAgent(agentDamages);
    }
  };
  const selectMaterial = (item, select) => {
    let list = listMaterial;
    for (let i = 0; i < list.length; i++) {
      const material = list[i];
      if (material.name === item) {
        list[i].enable = true;
      } else {
        list[i].enable = false;
      }
    }
    setlistMaterial([...list]);
    if (select) {
      setchooseMaterial(true);
    }
  };
  let deleteDamage = () => {
    let { damages, agentDamages } = props;
    const index =
      hoverIdDamage && hoverIdDamage.index ? hoverIdDamage.index : 0;
    const type = hoverIdDamage && hoverIdDamage.type ? hoverIdDamage.type : '';
    if (type === 'damage') {
      const index = hoverIdDamage.index;
      if (damages[index]) {
        damages.splice(hoverIdDamage.index, 1);
        props.deleteDamage(damages);
      }
    }
    if (type === 'damageInspected') {
      const rejectAdmin = document.getElementById(`reject-${index}`);
      if (rejectAdmin) {
        rejectAdmin.classList.add('show');
      }
      if (agentDamages[index]) {
        agentDamages[index].admin_response = 'reject';
        props.updateDamagesAgent(agentDamages);
      }
    }
  };
  const resetZoom = () => {
    let zoom = prismaZoom.current.getZoom();
    if (zoom > 1) {
      prismaZoom.current.reset();
    }
  };

  const toolHotKey = () => {
    let {
      imgSupport,
      onModalImage,
      offModalImage,
      inspectionInspect,
      taskInspect,
    } = props;
    const damage_table = inspectionInspect?.options?.damage_table ?? null;
    const checkBmw = damage_table ? damage_table.includes('bmw') : false;
    let photoSearch01, photoSearch02;

    switch (taskInspect && taskInspect.photoCode) {
      case '04':
        photoSearch01 = 'capture/10';
        photoSearch02 = 'capture/13';
        break;
      case '05':
        photoSearch01 = 'capture/10';
        photoSearch02 = 'capture/11';

        break;
      case '07':
        photoSearch01 = 'capture/11';
        photoSearch02 = 'capture/12';
        break;
      case '08':
        photoSearch01 = 'capture/12';
        photoSearch02 = 'capture/13';
        break;
      default:
        break;
    }
    if (
      ['04', '05', '07', '08'].includes(taskInspect.photoCode) &&
      imgSupport.length > 0
    ) {
      imgSupport = [
        ...imgSupport.filter((item) => item.includes(photoSearch01)),
        ...imgSupport.filter((item) => item.includes(photoSearch02)),
        ...imgSupport.filter(
          (item) =>
            !item.includes(photoSearch01) && !item.includes(photoSearch02),
        ),
      ];
    }

    return (
      <div className="tool-cage" style={{ display: 'none' }}>
        {listMaterial &&
        listMaterial.length > 0 &&
        !checkBmw &&
        damage_table !== 'autograde'
          ? listMaterial.map((item) => {
              return (
                <Hotkeys
                  key={`hotmaterial_` + item.hotKey}
                  keyName={`${item.hotKey}`}
                  onKeyDown={() => selectMaterial(item.name, true)}
                />
              );
            })
          : null}
        {!checkBmw && damage_table !== 'autograde' ? (
          <Hotkeys
            keyName="tab"
            onKeyDown={() => setshowModalMaterial(true)}
            onKeyUp={() => setshowModalMaterial(false)}
          ></Hotkeys>
        ) : null}
        <Hotkeys
          keyName="esc"
          onKeyDown={() => props.setmodalShowTimeOutSession()}
        ></Hotkeys>
        <Hotkeys keyName="space" onKeyDown={() => props.setRotate()}>
          <div onClick={() => props.setRotate()}></div>
        </Hotkeys>
        <Hotkeys keyName="x" onKeyDown={() => deleteDamage()}></Hotkeys>
        <Hotkeys keyName="r" onKeyDown={() => props.calShowCage()}></Hotkeys>
        <button
          type="button"
          className="btn btn-reset"
          onClick={() => resetZoom()}
        ></button>

        <Hotkeys
          keyName="s"
          onKeyDown={() => props.onModalImageFull()}
          onKeyUp={() => props.offModalImageFull()}
        ></Hotkeys>
        <Hotkeys keyName="d" onKeyDown={() => props.setshowDamages()}></Hotkeys>
        {inspectionInspect.sameInspection && (
          <Hotkeys
            keyName="c"
            onKeyDown={props.onModalCheckSameInspection}
            onKeyUp={props.offModalCheckSameInspection}
          ></Hotkeys>
        )}

        {imgSupport && imgSupport.length > 0
          ? imgSupport.map((item, index) => {
              return (
                <Hotkeys
                  key={`Image_` + index}
                  keyName={`${index + 1}`}
                  onKeyDown={() => onModalImage(item, index + 1)}
                  onKeyUp={() => offModalImage()}
                ></Hotkeys>
              );
            })
          : null}
        <Hotkeys
          keyName="w"
          onKeyDown={() => props.setScaleDown()}
          onKeyUp={() => props.setScaleUp()}
        ></Hotkeys>
        <Hotkeys keyName="q" onKeyUp={(e) => props.OnOfSvg(e)}></Hotkeys>
      </div>
    );
  };
  const checkDamageNotAction = (listDamage, classDamage, classRnd) => {
    for (let i = 0; i < listDamage.length; i++) {
      const dama = listDamage[i];
      if (!dama.damage_name) {
        const pointRnd = document.getElementById(`${classDamage}-${i}`);
        const point = document.getElementById(`${classRnd}-${i}`);
        if (pointRnd && point) {
          pointRnd.classList.add('heartBeat');
          point.classList.add('heartBeat');
          setTimeout(() => {
            pointRnd.classList.remove('heartBeat');
            point.classList.remove('heartBeat');
          }, 1000);
        }
        return true;
      }
    }
    return false;
  };
  const addDamage = (e) => {
    const zoom = prismaZoom.current.getZoom();
    let { damages, getDamages, vehicle, inspectionInspect, agentDamages } =
      props;
    const damage_table = inspectionInspect?.options?.damage_table ?? null;
    const checkBmw = damage_table ? damage_table.includes('bmw') : false;
    const model = vehicle && vehicle.model ? vehicle.model : false;
    const make = vehicle && vehicle.make ? vehicle.make : false;
    if (zoom === 1) {
      prismaZoom.current.reset();
    }
    if (zoom > 1) {
      prismaZoom.current.zoomToZone(null);
      prismaZoom.current.reset();
    }

    let offset = $('.App-zoom').offset();
    let offsetWidth = $('.App-zoom').width();
    let offsetHeight = $('.App-zoom').height();
    let relX = (e.pageX - offset.left) / zoom;
    let relY = (e.pageY - offset.top) / zoom;
    let partId = e.target.getAttribute('data-name');
    let partGroup = e.target.getAttribute('group');
    let partMaterial = e.target.getAttribute('material');
    if (!partId || !partGroup) {
      return;
    }
    let checkAction = false;
    if (damages && damages.length > 0) {
      checkAction = checkDamageNotAction(damages, 'damage-item', 'rnd');
      if (checkAction) {
        return;
      }
    }
    if (agentDamages && agentDamages.length > 0) {
      checkAction = checkDamageNotAction(
        agentDamages,
        'damage-item-agent',
        'rnd-agent',
      );
      if (checkAction) {
        return;
      }
    }
    const damageSource = inspectionInspect?.options?.damages_source ?? false;
    if (damage_table === 'autograde') {
      const item = {
        component: partId,
        damage_group: partGroup,
      };
      getDamages(item, damage_table);
    } else if (checkBmw) {
      const item = {
        component: partId,
        damage_group: partGroup,
        series_nme: make === 'MINI' ? 'MINI' : model,
        source: damage_table,
      };
      getDamages(item, damage_table);
    } else {
      const mate = listMaterial.find((item) => item.enable);
      let item = { component: partId, damage_group: partGroup };
      if (chooseMaterial) {
        item = {
          ...item,
          material: mate && mate.name ? mate.name : false,
        };
      } else {
        if (partMaterial) {
          item = {
            ...item,
            material: partMaterial,
          };
          selectMaterial(partMaterial);
        } else {
          switch (partGroup) {
            case 'BODY':
              selectMaterial('Painted');
              break;
            case 'PART':
              selectMaterial('Textured Surface');
              break;
            case 'GLASS':
              selectMaterial('Glass');
              break;
            default:
              break;
          }
          const mate = listMaterial.find((item) => item.enable);
          item = {
            ...item,
            material: mate && mate.name ? mate.name : null,
          };
        }
      }

      if (damageSource) {
        item = {
          ...item,
          source: damageSource,
        };
      }
      getDamages(item);
    }
    if (partId && partGroup !== 'TIRE') {
      damages.push({
        component: partId,
        damage_group: partGroup,
        damage_location: {
          x: parseFloat(Number(relX).toFixed(4)),
          y: parseFloat(Number(relY).toFixed(4)),
        },
        actual_image: {
          width: parseFloat(Number(offsetWidth).toFixed(4)),
          height: parseFloat(Number(offsetHeight).toFixed(4)),
        },
      });
      props.addDamages(damages);
    }
  };
  const showImageFull = () => {
    let {
      taskInspect,
      img,
      damages,
      svg,
      cagePo,
      rotate,
      scale,
      agentDamages,
      disableDragging,
      checkTest,
      listDamages,
      vehicle,
      inspectionInspect,
      showAIDamage,
      showAISVGDamage,
      showAICage,
      user,
      runOneTimeFinish,
      cageAI,
      showDamages,
    } = props;
    const aiData = taskInspect?.aiData ?? null;
    const skillsUser = user?.skills ?? [];
    let showAi = false;
    if (
      skillsUser.includes('AI') ||
      ['TRAINER', 'SUPER', 'ADMIN'].includes(user.role)
    ) {
      showAi = true;
    }
    const mate = listMaterial.find((item) => item.enable);
    const damage_table = inspectionInspect?.options?.damage_table ?? null;
    const checkBmw = damage_table ? damage_table.includes('bmw') : false;
    let timeInspect = taskInspect?.timeInspect ?? 30;
    let statusTime = false;
    const styles = reactCSS({
      default: {
        img: {
          transform: `rotate(${rotate}deg)`,
        },
      },
    });
    let inpectStartAt = taskInspect?.inpectStartAt ?? null;
    if (inpectStartAt && timeInspect > 2) {
      inpectStartAt = new Date(inpectStartAt);
      const timeStartAt = inpectStartAt.getTime();
      if (
        ['PROCESSING'].includes(taskInspect.status) &&
        ['AGENT'].includes(user.role)
      ) {
        statusTime = true;
      }

      const timeAutoInspected = timeStartAt + timeInspect * 1000;
      const now = Date.now();
      timeInspect = (timeAutoInspected - now) / 1000;
      if (['AGENT'].includes(user.role)) {
        if (timeInspect < 5) {
          props.setshowBtnFinish(false);
        }
        if (timeInspect < 3 && !runOneTimeFinish) {
          props.finishImage();
          props.setrunOneTimeFinish(true);
        }
      }
    }
    const uiTimerTask = () => {
      return (
        <div
          className={`timer badge badge-${
            statusTime ? 'warning' : 'dark'
          } text-head `}
          style={{
            position: 'absolute',
            right: '15px',
            top: '15px',
            fontSize: '16px',
            zIndex: 99999,
          }}
        >
          <i className={`fas fa-history ${statusTime ? 'fa-spin' : ''}`}></i>
          <span className="ml-1">{Math.floor(timeInspect)} s</span>
        </div>
      );
    };
    const uiCageAI = () => {
      const x = cageAI.x;
      const y = cageAI.y;
      const width = cageAI.width;
      const height = cageAI.height;
      const svgAI = aiData?.svg ?? '';
      return (
        <Rnd
          className="rnd-cage"
          style={{
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '0 0',
            backgroundSize: 'contain',
            border: '2px dashed yellow',
            cursor: mate && mate.url ? mate.url : '',
          }}
          size={{
            width: width,
            height: height,
          }}
          position={{
            x: x,
            y: y,
          }}
          onDrag={(e, d) => {
            props.resizePositionAI({
              x: d.x,
              y: d.y,
            });
          }}
          onResize={(e, direction, ref, delta, position) => {
            const width = ref.style.width;
            const height = ref.style.height;
            const x = position.x;
            const y = position.y;
            props.resizeAI(width, height, x, y);
          }}
          //bounds=".img-full"
          lockAspectRatio={true}
          disableDragging={disableDragging}
        >
          <SVG
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              zIndex: '101',
            }}
            className={'svg cageAI'}
            src={svgAI}
            uniquifyIDs={false}
          ></SVG>
        </Rnd>
      );
    };
    const uiDamageAI = () => {
      const damages = aiData?.damages ?? [];
      let offsetWidth = $('.App-zoom').width();
      let offsetHeight = $('.App-zoom').height();
      return showAi && aiData
        ? damages.map((damage, index) => {
            let boxDamagePoint = {
              position: 'absolute',
              zIndex: 88,
              color: 'yellow',
            };
            if (damage.keypoint) {
              const xMin = damage?.keypoint[0] * offsetWidth;
              const yMin = damage?.keypoint[1] * offsetHeight;
              boxDamagePoint.left = `${xMin - 11}px`;
              boxDamagePoint.top = `${yMin - 8}px`;
            }
            const label = damage['damage-label'] ?? null;
            return (
              <div key={`AIDamage-${index}`} style={boxDamagePoint}>
                {label ? (
                  <span
                    style={{
                      position: 'absolute',
                      top: '-22px',
                      left: '-15px',
                      margin: 0,
                    }}
                  >
                    {label}
                  </span>
                ) : null}
                {damage.keypoint ? (
                  <i
                    style={{ margin: 0 }}
                    className="far fa-lg fa-dot-circle"
                  ></i>
                ) : null}
              </div>
            );
          })
        : null;
    };
    const uiSvgDamageAI = () => {
      const dmg_svg = aiData?.dmg_svg ?? null;
      //const x = cageAI.x;
      //const y = cageAI.y;
      // const width = cageAI.width;
      // const height = cageAI.height;
      const boxStyle = {
        position: 'absolute',
        left: `${0}px`,
        top: `${0}px`,
        width: `100%`,
        height: `100%`,
        boxSizing: 'border-box',
        zIndex: 88,
      };
      return skillsUser.includes('AI') && aiData && dmg_svg ? (
        <SVG
          style={boxStyle}
          className={'svg damageAI'}
          src={dmg_svg}
          uniquifyIDs={false}
        ></SVG>
      ) : null;
    };
    const formatOptionLabel = ({
      label,
      unit_measure,
      grade_score,
      material,
    }) => (
      <div style={{ display: 'flex' }}>
        {material ? (
          <div
            style={{ color: '#fff' }}
            className="badge badge-primary text-head text-uppercase"
          >
            {material === 'Painted' ? (
              <i className="fas fa-fill-drip"></i>
            ) : material === 'Textured Surface' ? (
              <i className="fas fa-recycle"></i>
            ) : material === 'Chrome' ? (
              <i className="far fa-gem"></i>
            ) : material === 'Alloy Wheel' ? (
              <i className="fas fa-drum-steelpan"></i>
            ) : material === 'Plastic Cover' ? (
              <i className="fas fa-record-vinyl"></i>
            ) : material === 'Steel Wheel' ? (
              <i className="fas fa-dumbbell"></i>
            ) : material === 'Glass' ? (
              <i className="fas fa-glass-whiskey"></i>
            ) : material === 'Steel' ? (
              <i className="fas fa-dharmachakra"></i>
            ) : null}
          </div>
        ) : null}
        <div
          style={{ color: '#fff' }}
          className="badge badge-primary text-head text-uppercase ml-1"
        >
          {unit_measure &&
          (unit_measure.includes('Component') ||
            unit_measure.includes('component'))
            ? 'C'
            : 'I'}
        </div>
        <div
          className="badge badge-primary text-head text-uppercase ml-1"
          style={{
            color: grade_score === 0 ? '#fff' : '#000',
            background:
              grade_score === 0
                ? 'grey'
                : grade_score === 1
                ? 'yellow'
                : grade_score === 2
                ? 'orange'
                : grade_score >= 3
                ? 'red'
                : '',
          }}
        >
          {label}
        </div>
        <div
          style={{
            color: grade_score === 0 ? '#fff' : '#000',
            background:
              grade_score === 0
                ? 'grey'
                : grade_score === 1
                ? 'yellow'
                : grade_score === 2
                ? 'orange'
                : grade_score >= 3
                ? 'red'
                : '',
          }}
          className="badge badge-primary text-head text-uppercase ml-1"
        >
          {grade_score}
        </div>
      </div>
    );
    let damagePointAgent = [];
    let damagePoint = [];
    let typeSelectList = [];
    const damagesAPI =
      listDamages &&
      listDamages.success &&
      listDamages.damages &&
      listDamages.damages.length
        ? listDamages.damages
        : false;
    if (damagesAPI) {
      for (let h = 0; h < damagesAPI.length; h++) {
        const damage = damagesAPI[h];
        typeSelectList.push({
          ...damage,
          damage_type: damage.damage_type
            ? damage.damage_type
            : damage.damage_name
            ? damage.damage_name
            : '',
          des: damage.label,
          label: damage.damage_name,
        });
      }
    }
    if (agentDamages && agentDamages.length > 0) {
      for (let i = 0; i < agentDamages.length; i++) {
        const item = agentDamages[i];
        const style = {
          top: item.y,
          left: item.x,
          color:
            item.grade_score === 0
              ? 'grey'
              : item.grade_score === 1
              ? 'yellow'
              : item.grade_score === 2
              ? 'orange'
              : item.grade_score >= 3
              ? 'red'
              : '',
        };
        if (item.admin_response !== 'reject') {
          if (
            inspectionInspect.sameInspection &&
            !['DEFAULT'].includes(inspectionInspect.group)
          ) {
            damagePointAgent.push(
              <Rnd
                className="damage-point"
                key={`damage-agent-${i}`}
                onMouseEnter={() =>
                  sethoverIdDamage({
                    index: i,
                    type: 'damageInspected',
                  })
                }
                style={{
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '0 0',
                  backgroundSize: 'contain',
                  zIndex: 999,
                }}
                position={{
                  x: item.x,
                  y: item.y,
                }}
                onDrag={(e, d) => {
                  props.resizeXYDamage(i, d.x, d.y);
                }}
                //bounds=".img-full"
                lockAspectRatio={true}
                enableResizing={false}
              >
                <div className="agent-point">A-{i + 1}</div>
                <i
                  id={`rnd-agent-${i}`}
                  style={{
                    color:
                      item.grade_score === 0
                        ? 'grey'
                        : item.grade_score === 1
                        ? 'yellow'
                        : item.grade_score === 2
                        ? 'orange'
                        : item.grade_score >= 3
                        ? 'red'
                        : '',
                  }}
                  className="far fa-lg fa-dot-circle"
                ></i>
                {!item.damage_name ? (
                  item.x > item.actual_image.width / 2 ? (
                    <Select
                      options={typeSelectList}
                      menuPortalTarget={document.body}
                      menuPlacement={
                        item && item.y > item.actual_image.height / 2
                          ? 'top'
                          : 'bottom'
                      }
                      isClearable={false}
                      formatOptionLabel={formatOptionLabel}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      onChange={(e) => updateDamageAgent(e, i)}
                      className="react-select-damage-left"
                      classNamePrefix="react-select"
                    />
                  ) : (
                    <Select
                      options={typeSelectList}
                      isClearable={false}
                      menuPortalTarget={document.body}
                      menuPlacement={
                        item.damage_location &&
                        item.damage_location.y > item.actual_image.height / 2
                          ? 'top'
                          : 'bottom'
                      }
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      formatOptionLabel={formatOptionLabel}
                      onChange={(e) => updateDamageAgent(e, i)}
                      className="react-select-damage-right"
                      classNamePrefix="react-select"
                    />
                  )
                ) : null}
              </Rnd>,
            );
          } else {
            damagePointAgent.push(
              <div
                key={`Damage_${i}_agent`}
                id={`rnd-agent-${i}`}
                className="damage-point"
                style={style}
                onMouseEnter={() =>
                  sethoverIdDamage({
                    index: i,
                    type: 'damageInspected',
                  })
                }
                onMouseLeave={() => sethoverIdDamage(null)}
              >
                <div className="agent-point">A-{i + 1}</div>
                <i
                  style={{
                    color:
                      item.grade_score === 0
                        ? 'grey'
                        : item.grade_score === 1
                        ? 'yellow'
                        : item.grade_score === 2
                        ? 'orange'
                        : item.grade_score >= 3
                        ? 'red'
                        : '',
                  }}
                  className="far fa-lg fa-dot-circle"
                ></i>
                {!item.damage_name ? (
                  item.x > item.actual_image.width / 2 ? (
                    <Select
                      options={typeSelectList}
                      menuPortalTarget={document.body}
                      menuPlacement={
                        item && item.y > item.actual_image.height / 2
                          ? 'top'
                          : 'bottom'
                      }
                      isClearable={false}
                      formatOptionLabel={formatOptionLabel}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      onChange={(e) => updateDamageAgent(e, i)}
                      className="react-select-damage-left"
                      classNamePrefix="react-select"
                    />
                  ) : (
                    <Select
                      options={typeSelectList}
                      isClearable={false}
                      menuPortalTarget={document.body}
                      menuPlacement={
                        item.damage_location &&
                        item.damage_location.y > item.actual_image.height / 2
                          ? 'top'
                          : 'bottom'
                      }
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      formatOptionLabel={formatOptionLabel}
                      onChange={(e) => updateDamageAgent(e, i)}
                      className="react-select-damage-right"
                      classNamePrefix="react-select"
                    />
                  )
                ) : null}
              </div>,
            );
          }
        }
      }
    }
    for (let i = 0; i < damages.length; i++) {
      let damage = damages[i];
      const style = {
        top:
          damage.damage_location && damage.damage_location.y
            ? damage.damage_location.y
            : null,
        left:
          damage.damage_location && damage.damage_location.x
            ? damage.damage_location.x
            : null,
      };

      damagePoint.push(
        <div
          key={`Damage_${i}`}
          id={`rnd-${i}`}
          className="damage-point"
          style={style}
          onMouseEnter={() =>
            sethoverIdDamage({
              index: i,
              type: 'damage',
            })
          }
          onMouseLeave={() => sethoverIdDamage(null)}
        >
          {damage.damage_location &&
          damage.damage_location.x > damage.actual_image.width / 2 ? (
            <div className="admin-point-right">{i + 1}</div>
          ) : (
            <div className="admin-point">{i + 1}</div>
          )}
          <i
            style={{
              color:
                damage.grade_score === 0
                  ? 'grey'
                  : damage.grade_score === 1
                  ? 'yellow'
                  : damage.grade_score === 2
                  ? 'orange'
                  : damage.grade_score >= 3
                  ? 'red'
                  : '',
            }}
            className="far fa-lg fa-dot-circle"
          ></i>
          {!damage.damage_name ? (
            damage.damage_location &&
            damage.damage_location.x > damage.actual_image.width / 2 ? (
              <Select
                options={typeSelectList}
                menuPortalTarget={document.body}
                classNamePrefix={
                  damage.damage_location &&
                  damage.damage_location.y > damage.actual_image.height / 2
                    ? 'top'
                    : 'bottom'
                }
                isClearable={false}
                formatOptionLabel={formatOptionLabel}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                }}
                onChange={(e) => updateDamage(e, i)}
                className="react-select-damage-left"
              />
            ) : (
              <Select
                options={typeSelectList}
                isClearable={false}
                menuPortalTarget={document.body}
                classNamePrefix={
                  damage.damage_location &&
                  damage.damage_location.y > damage.actual_image.height / 2
                    ? 'top'
                    : 'bottom'
                }
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                }}
                formatOptionLabel={formatOptionLabel}
                onChange={(e) => updateDamage(e, i)}
                className="react-select-damage-right"
              />
            )
          ) : null}
        </div>,
      );
    }
    if (['01', '02', '03', '06', '15'].includes(taskInspect.photoCode)) {
      return (
        <>
          <div className="tool-image">
            {checkTest ? <Testing /> : null}
            <div className="img-full" id="imgProcess">
              {uiTimerTask()}
              <PrismaZoom
                className="App-zoom"
                style={{ overflow: 'scroll' }}
                minZoom={1}
                maxZoom={scale}
                leftBoundary={0}
                topBoundary={0}
                onZoomChange={(e) => props.onZoomChange(e)}
                ref={prismaZoom}
              >
                <img style={styles.img} src={img} alt="" />
              </PrismaZoom>
            </div>
            {toolHotKey()}
          </div>
          {vehicle ? (
            <div
              className="info mt-2 mb-2"
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div
                className="group-info"
                style={{
                  background: '#fff',
                  padding: '5px 25px',
                  fontSize: '17px',
                  color: 'red',
                  borderRadius: '14px',
                  textTransform: 'uppercase',
                }}
              >
                <span className="text-head">
                  {vehicle && vehicle.year ? vehicle.year : ''}-
                  {vehicle && vehicle.make ? vehicle.make : ''}-
                  {vehicle && vehicle.model ? vehicle.model : ''}-
                  {vehicle && vehicle.bodyType ? vehicle.bodyType : ''}
                </span>
              </div>
            </div>
          ) : null}
        </>
      );
    } else {
      return (
        <div>
          <div className="tool-image" style={{ display: 'flex' }}>
            {checkTest ? <Testing /> : null}
            <div
              className="img-full"
              id="imgProcess"
              onDoubleClick={(e) => addDamage(e)}
            >
              {uiTimerTask()}
              {!checkBmw && showModalMaterial ? modalMaterial() : null}
              {toolHotKey()}
              <PrismaZoom
                className="App-zoom"
                minZoom={1}
                maxZoom={scale}
                onZoomChange={(e) => props.onZoomChange(e)}
                ref={prismaZoom}
              >
                <img style={styles.img} src={img} alt="" />
                {showAICage && skillsUser.includes('AI') ? uiCageAI() : null}
                <Rnd
                  className="rnd-cage"
                  style={{
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '0 0',
                    backgroundSize: 'contain',
                    cursor: mate && mate.url ? mate.url : '',
                  }}
                  size={{
                    width: cagePo.width,
                    height: cagePo.height,
                  }}
                  position={{
                    x: cagePo.x,
                    y: cagePo.y,
                  }}
                  onDrag={(e, d) => {
                    props.resizeXY({
                      x: d.x,
                      y: d.y,
                    });
                  }}
                  onResize={(e, direction, ref, delta, position) => {
                    const width = ref.style.width;
                    const height = ref.style.height;
                    const x = position.x;
                    const y = position.y;
                    props.resizeTop(width, height, x, y);
                  }}
                  //bounds=".img-full"
                  lockAspectRatio={true}
                  disableDragging={disableDragging}
                >
                  {svg ? (
                    <SVG
                      className={'imageHolder hoverable svg cage'}
                      src={svg}
                      uniquifyIDs={false}
                      style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 99,
                      }}
                    ></SVG>
                  ) : null}
                </Rnd>
                {showDamages ? damagePoint : null}
                {showDamages ? damagePointAgent : null}
                {showAISVGDamage ? uiSvgDamageAI() : null}
                {showAIDamage ? uiDamageAI() : null}
              </PrismaZoom>
            </div>
            {!checkBmw && damage_table !== 'autograde' ? toolMaterial() : null}
          </div>
          {vehicle ? (
            <div
              className="info mt-2 mb-2"
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div
                className="group-info"
                style={{
                  background: '#fff',
                  padding: '5px 25px',
                  fontSize: '17px',
                  color: 'red',
                  borderRadius: '15px',
                  textTransform: 'uppercase',
                }}
              >
                <span className="text-head">
                  {vehicle && vehicle.year ? vehicle.year : ''}-
                  {vehicle && vehicle.make ? vehicle.make : ''}-
                  {vehicle && vehicle.model ? vehicle.model : ''}-
                  {vehicle && vehicle.bodyType ? vehicle.bodyType : ''}
                </span>
              </div>
            </div>
          ) : null}
        </div>
      );
    }
  };

  return <div>{showImageFull()}</div>;
};

export default PhotoFull;
