import React from 'react';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
const Total = (props) => {
  const { statistic } = props;
  const totalTask = statistic && statistic.totalTask ? statistic.totalTask : 0;
  const totalTaskFailed =
    statistic && statistic.totalTaskFailed ? statistic.totalTaskFailed : 0;
  const dataInspector =
    statistic && statistic.dataInspector ? statistic.dataInspector : 0;
  const dataQa = statistic && statistic.dataQa ? statistic.dataQa : 0;
  const totalTaskMistakeIns =
    dataInspector.criticalMistake + dataInspector.mistake;
  const totalTaskMistakeQa = dataQa.criticalMistake + dataQa.mistake;
  const caculatePercentInspector =
    totalTaskMistakeIns && totalTask
      ? ((totalTaskMistakeIns / totalTask) * 100).toFixed(2)
      : 0;
  const caculatePercentQa =
    totalTaskMistakeQa && totalTaskFailed
      ? ((totalTaskMistakeQa / totalTaskFailed) * 100).toFixed(2)
      : 0;
  const data = [];
  return (
    <div className="total d-flex">
      <div
        className="box-total"
        style={{
          width: '30%',
          margin: '5px',
        }}
      >
        <div
          className="number mb-2"
          style={{
            width: '50%',
            display: 'inline-block',
          }}
        >
          <div
            className="box-count"
            style={{
              padding: '15px 12px',
              margin: '0 5px',
              borderRadius: '10px',
              background: '#321640',
              textAlign: 'center',
            }}
          >
            <div className="text-head text-white">
              <div
                className="action"
                style={{
                  textTransform: 'uppercase',
                  fontSize: '20px',
                }}
              >
                Task Slot
              </div>
              <div
                className="number text-dark"
                style={{
                  fontSize: '35px',
                  background: '#fff',
                  borderRadius: '5px',
                }}
              >
                {totalTask}
              </div>
            </div>
          </div>
        </div>
        <div
          className="number mb-2"
          style={{
            width: '50%',
            display: 'inline-block',
          }}
        >
          <div
            className="box-count"
            style={{
              padding: '15px 12px',
              margin: '0 5px',
              borderRadius: '10px',
              background: '#321640',
              textAlign: 'center',
            }}
          >
            <div className="text-head text-white">
              <div
                className="action"
                style={{
                  textTransform: 'uppercase',
                  fontSize: '20px',
                }}
              >
                Inspector
              </div>
              <div
                className="number text-dark"
                style={{
                  fontSize: '35px',
                  background: '#fff',
                  borderRadius: '5px',
                }}
              >
                {caculatePercentInspector}%
              </div>
            </div>
          </div>
        </div>
        <div
          className="number mb-2"
          style={{
            width: '50%',
            display: 'inline-block',
          }}
        >
          <div
            className="box-count"
            style={{
              padding: '15px 12px',
              margin: '0 5px',
              borderRadius: '10px',
              background: '#321640',
              textAlign: 'center',
            }}
          >
            <div className="text-head text-white">
              <div
                className="action"
                style={{
                  textTransform: 'uppercase',
                  fontSize: '20px',
                }}
              >
                Tasks Failed
              </div>
              <div
                className="number text-dark"
                style={{
                  fontSize: '35px',
                  background: '#fff',
                  borderRadius: '5px',
                }}
              >
                {totalTaskFailed}
              </div>
            </div>
          </div>
        </div>
        <div
          className="number mb-2"
          style={{
            width: '50%',
            display: 'inline-block',
          }}
        >
          <div
            className="box-count"
            style={{
              padding: '15px 12px',
              margin: '0 5px',
              borderRadius: '10px',
              background: '#321640',
              textAlign: 'center',
            }}
          >
            <div className="text-head text-white">
              <div
                className="action"
                style={{
                  textTransform: 'uppercase',
                  fontSize: '20px',
                }}
              >
                Qa
              </div>
              <div
                className="number text-dark"
                style={{
                  fontSize: '35px',
                  background: '#fff',
                  borderRadius: '5px',
                }}
              >
                {caculatePercentQa}%
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="chart text-head"
        style={{
          width: '70%',
          margin: '5px',
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            width={'100%'}
            height={'100%'}
            data={data}
            margin={{
              top: 5,
              right: 5,
              bottom: 5,
              left: 5,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="name" scale="band" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="total" barSize={30} fill="#321640" />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Total;
