import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import SVG from 'react-inlinesvg';
import {
  getSessionRd,
  updateAction,
  startWork,
  stopWork,
  startValidate,
  stopValidate,
  clearInspection,
} from '../operations';
import { logout } from '../../auth/operations';
import ModalMD from './components/partials/ModalMD';
import Permission from '../../ui/containers/Permission';
import CarAni from '../../ui/components/animations/CarAni';
import BoxHotKeys from '../../settings/containers/components/BoxHotKeys';
const InspectionsRd = (props) => {
  const { auth, inspection } = props;
  const role = auth && auth.user && auth.user.role ? auth.user.role : '';
  const [modalStart, setmodalStart] = useState(false);
  const working = inspection?.working ?? false;
  const validate = inspection?.validate ?? false;
  const dataRd = inspection?.dataRd ?? null;
  const sessionID = dataRd?.sessionID ?? null;
  useEffect(() => {
    if (sessionID) {
      const currentDomain = window.location.origin;
      const newPath = `/inspectionView/${sessionID}`;
      window.location.href = currentDomain + newPath;
    }
    return () => {
      props.clearInspection();
    };
  }, [sessionID]);
  useEffect(() => {
    let timerRdInspection;
    if (working) {
      timerRdInspection = setInterval(() => {
        props.getSessionRd(role, validate);
      }, 5000);
    } else {
      clearInterval(timerRdInspection);
    }
    return () => {
      clearInterval(timerRdInspection);
    };
  }, [working]);
  const updateActionUser = (status) => {
    const data = {};
    data['status'] = status;
    const elementMenu = document.getElementById('listMenu');
    if (status === 'Online') {
      props.stopWork();
      if (elementMenu) {
        elementMenu.classList.remove('disable');
      }
    } else {
      if (elementMenu) {
        elementMenu.classList.add('disable');
      }
      props.startWork();
    }
    setmodalStart(false);
    props.updateAction(data);
  };

  const onToggleValidate = () => {
    if (!validate) {
      props.startValidate();
    } else {
      props.stopValidate();
    }
  };
  return ['AGENT', 'QA'].includes(role) ? (
    <div id="Inspection_list">
      {!working ? (
        <div className="start-info mt-4">
          <div className="start-inspect container-fluid">
            <div className="row">
              <div className="col-3">
                <CarAni />
                <div
                  style={{
                    position: 'absolute',
                    width: '95%',
                    display: 'flex',
                    justifyContent: 'center',
                    top: '180px',
                    zIndex: '9999',
                  }}
                >
                  <div className="stop">
                    {role === 'QA' ? (
                      <div
                        className="custom-control custom-switch text-head"
                        style={{ display: 'flex' }}
                      >
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="inputVehicle"
                          name="inputVehicle"
                          checked={validate}
                          onChange={onToggleValidate}
                        />
                        {!validate ? (
                          <label
                            className="custom-control-label"
                            htmlFor="inputVehicle"
                          >
                            Check
                          </label>
                        ) : (
                          <label
                            className="custom-control-label"
                            htmlFor="inputVehicle"
                          >
                            Validate
                          </label>
                        )}
                      </div>
                    ) : null}
                    <div
                      className="badge badge-dark text-uppercase text-head d-block display-4"
                      style={{
                        padding: '10px 15px',
                        fontSize: '19px',
                        cursor: 'pointer',
                      }}
                      onClick={() => setmodalStart(true)}
                    >
                      {role === 'AGENT'
                        ? 'INSPECT'
                        : role === 'QA' && !validate
                        ? 'CHECK'
                        : role === 'QA' && validate
                        ? 'VALIDATE'
                        : ''}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-9">
                <BoxHotKeys />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="process d-flex justify-content-center align-items-center mt-4"
          style={{
            width: '100%',
          }}
        >
          <div
            className="back"
            style={{
              position: 'absolute',
              left: '30px',
              top: '25px',
              color: '#000',
              fontSize: '38px',
              zIndex: '999',
              cursor: 'pointer',
            }}
            onClick={() => updateActionUser('Online')}
          >
            <i className="fas fa-backspace"></i>
          </div>
          <SVG
            className={'svg'}
            src={`img/svg/${
              role === 'AGENT'
                ? 'ani1'
                : role === 'QA' && !validate
                ? 'ani2'
                : role === 'QA' && validate
                ? 'ani3'
                : ''
            }.svg`}
            uniquifyIDs={false}
            style={{
              width: '39%',
            }}
          ></SVG>
        </div>
      )}

      <div className="rd"></div>
      <ModalMD
        show={modalStart}
        title={
          role === 'AGENT'
            ? 'INSPECT'
            : role === 'QA' && !validate
            ? 'CHECK'
            : role === 'QA' && validate
            ? 'VALIDATE'
            : ''
        }
        body={
          <div className="main-content">
            <div className="form-group">
              <div className="text-center">
                <SVG
                  className={'svg'}
                  src={`img/svg/${
                    role === 'AGENT'
                      ? 'ani1'
                      : role === 'QA' && !validate
                      ? 'ani2'
                      : role === 'QA' && validate
                      ? 'ani3'
                      : ''
                  }.svg`}
                  uniquifyIDs={false}
                  style={{
                    width: '100%',
                  }}
                ></SVG>
                <span className="text-head" style={{ fontSize: '20px' }}>
                  {`Are you ready to ${
                    role === 'AGENT'
                      ? 'INSPECT'
                      : role === 'QA' && !validate
                      ? 'CHECK'
                      : role === 'QA' && validate
                      ? 'VALIDATE'
                      : ''
                  }`}
                </span>
              </div>
            </div>
            <div className="btn-group float-right">
              <button
                className="btn btn-warning"
                onClick={() =>
                  updateActionUser(
                    role === 'AGENT'
                      ? 'Inspecting'
                      : role === 'QA' && !validate
                      ? 'Checking'
                      : role === 'QA' && validate
                      ? 'Validating'
                      : '',
                  )
                }
              >
                <span className="text-bold" style={{ fontSize: '14px' }}>
                  Yes
                </span>
              </button>
              <button
                className="btn btn-primary"
                onClick={() => setmodalStart(false)}
              >
                <span className="text-bold" style={{ fontSize: '14px' }}>
                  No
                </span>
              </button>
            </div>
          </div>
        }
        sizeModal="modal-md"
        handleClose={() => setmodalStart(false)}
      />
    </div>
  ) : (
    <Permission />
  );
};

const mapStateToProps = (state) => ({
  inspection: state.inspection,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getSessionRd,
  updateAction,
  clearInspection,
  startWork,
  stopWork,
  startValidate,
  stopValidate,
  logout,
})(InspectionsRd);
