export const statusOptions = {
  'IN-QUEUE': {
    style: 'secondary',
    label: 'In Queue',
  },
  PROCESSING: {
    style: 'primary',
    label: 'Processing',
  },
  INSPECTING: {
    style: 'primary',
    label: 'Inspecting',
  },
  CHECKING: {
    style: 'primary',
    label: 'Checking',
  },
  INVALID: {
    style: 'danger',
    label: 'INVALID',
  },
  HOLDING: {
    style: 'warning',
    label: 'HOLDING',
  },
  'NEED-VALIDATE': {
    style: 'warning',
    label: 'Need Validate',
  },
  'VIN-CHECKING': {
    style: 'warning',
    label: 'Vin Checking',
  },
  'VIN-CONFIRM': {
    style: 'warning',
    label: 'Vin Confirm',
  },
  'NEED-CONFIRM': {
    style: 'warning',
    label: 'Need Confirm',
  },
  VALIDATING: {
    style: 'warning',
    label: 'Validating',
  },
  INSPECTED: {
    style: 'success',
    label: 'Inspected',
  },
  CONFIRMED: {
    style: 'success',
    label: 'Confirmed',
  },
  FINISHED: {
    style: 'success',
    label: 'Finished',
  },
  CANCELLED: {
    style: 'danger',
    label: 'Cancelled',
  },
  COMPLETED: {
    style: 'success',
    label: 'PAVE',
  },
  REJECTED: {
    style: 'warning',
    label: 'Rejected',
  },
  TIMEOUTQC: {
    style: 'danger',
    label: 'TIMEOUTQC',
  },
  TIMEOUTINS: {
    style: 'danger',
    label: 'TIMEOUTINSPECT',
  },
};
