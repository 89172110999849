import React, { useEffect, useState } from 'react';
//import { Link } from 'react-router-dom';
import _orderBy from 'lodash/orderBy';
import { connect } from 'react-redux';
import { getAgents, clearSuccess, updateUsers } from '../operations';
import Pagination from '../../../components/common/Pagination';
import Permission from '../../ui/containers/Permission';
import UserListTableSkills from './components/UserListTableSkills';

const AgentSkills = (props) => {
  const { user } = props.auth;
  const { list } = props.user;
  const { success } = props.user;
  const { listRole, listShifts } = props.user.settings;
  let [filters, setfilters] = useState({});
  let [limit, setlimit] = useState(10);
  let [page, setpage] = useState(1);
  const pages = list && list.pages ? list.pages : 1;
  const count = list && list.count ? list.count : 1;
  const timezone =
    user && user.options && user.options.timezone
      ? user.options.timezone
      : 'Asia/Ho_Chi_Minh';
  const [skills, setskills] = useState([{ name: 'AI', checked: false }]);
  const [shifts, setshifts] = useState([
    { name: 'A', checked: false },
    { name: 'B', checked: false },
    { name: 'C', checked: false },
    { name: 'D', checked: false },
    { name: 'E', checked: false },
    { name: 'F', checked: false },
  ]);
  const [groups, setgroups] = useState([
    { name: 'DEFAULT', checked: false },
    { name: 'AMZ', checked: false },
    { name: 'AI-EXP', checked: false },
  ]);
  let [dataUsers, setdataUsers] = useState([]);
  const [runOneTime, setrunOneTime] = useState(false);
  let [listUsers, setlistUsers] = useState([]);
  const [sort, setsort] = useState(false);
  useEffect(() => {
    if (process.env.REACT_APP_APP_ENV === 'development') {
      setgroups([
        { name: 'DEFAULT', checked: false },
        { name: 'AMZ', checked: false },
        { name: 'AI-EXP', checked: false },
        { name: 'AMZ-1', checked: false },
        { name: 'AMZ-2', checked: false },
        { name: 'AMZ-3', checked: false },
        { name: 'DEF-1', checked: false },
        { name: 'DEF-2', checked: false },
        { name: 'DEF-3', checked: false },
      ]);
    }
    if (!runOneTime) {
      props.getAgents(
        {
          filters,
          page: page,
          limit: limit,
        },
        true,
      );
    }
    if (success) {
      const data = _orderBy(list.data, ['username'], ['desc']);
      setdataUsers([...data]);
      setrunOneTime(true);
      props.clearSuccess();
    }

    // eslint-disable-next-line
  }, [limit, page, filters, success]);
  const onPageChange = (e) => {
    e.preventDefault();
    if (e.target.getAttribute('data-value')) {
      limit = parseInt(e.target.getAttribute('data-value') || limit, 10);
    }
    if (e.target.getAttribute('data-page')) {
      page = parseInt(e.target.getAttribute('data-page'), 10);
    } else if (e.target.value) {
      page = parseInt(e.target.value, 10);
    }
    props.getAgents(
      {
        page: page,
        limit: limit,
        filters: filters,
      },
      true,
    );
    setpage(page);
    setlimit(limit);
  };
  const onSort = (sort) => {
    if (sort) {
      dataUsers = _orderBy(dataUsers, ['username'], ['asc']);
    }
    if (!sort) {
      dataUsers = _orderBy(dataUsers, ['username'], ['desc']);
    }
    setsort(sort);
    setdataUsers([...dataUsers]);
  };
  const onChange = (e) => {
    let filter = filters;
    filter[e.target.name] = e.target.value;
    props.getAgents(
      {
        filters: filter,
        limit: limit,
      },
      true,
    );
    setpage(1);
    setfilters(filter);
  };
  const onChangeSkills = (e) => {
    skills[e.target.value].checked = !skills[e.target.value].checked;
    setskills([...skills]);
  };
  const onChangeGroups = (e) => {
    groups[e.target.value].checked = !groups[e.target.value].checked;
    setgroups([...groups]);
  };
  const onChangeShift = (e) => {
    for (let i = 0; i < shifts.length; i++) {
      if (parseInt(e.target.value) === i) {
        shifts[i].checked = true;
        continue;
      }
      shifts[i].checked = false;
    }
    setshifts([...shifts]);
  };
  const onCheckSelect = (username) => {
    const checkUser = listUsers.find((v) => v === username);
    if (!checkUser) {
      listUsers.push(username);
      setlistUsers([...listUsers]);
    }
  };
  const unCheckSelect = (index) => {
    listUsers.splice(index, 1), setlistUsers([...listUsers]);
  };
  const selectAll = () => {
    listUsers = dataUsers.map((v) => {
      return v.username;
    });
    setlistUsers([...listUsers]);
  };
  const updateSkills = async () => {
    const listSkills = skills.filter((v) => v.checked).map((v) => v.name);
    const listGroups = groups.filter((v) => v.checked).map((v) => v.name);
    const listShifts = shifts.filter((v) => v.checked).map((v) => v.name);
    const data = {
      skills: listSkills,
      groups: listGroups,
      shift: listShifts.length > 0 ? listShifts[0] : '',
      listUsers: listUsers,
    };
    const check = await props.updateUsers(data);
    if (check) {
      props.getAgents(
        {
          page: page,
          limit: limit,
          filters: filters,
        },
        true,
      );
    }
  };
  return (
    <div id="User_list">
      {!['QA', 'AGENT', 'VIEWER', 'TRAINEE'].includes(user.role) ? (
        <div className="container-fluid">
          <h2 className="subtitle">Update Skills Agents</h2>
          <Pagination
            size={limit}
            records={count}
            pages={pages}
            page={page}
            action={onPageChange}
          />
          <UserListTableSkills
            onChangeSkills={onChangeSkills}
            onChangeGroups={onChangeGroups}
            onChangeShift={onChangeShift}
            selectAll={selectAll}
            data={dataUsers}
            filters={filters}
            onChange={onChange}
            timezone={timezone}
            listRole={listRole}
            user={user}
            shifts={shifts}
            skills={skills}
            groups={groups}
            onCheckSelect={onCheckSelect}
            updateSkills={updateSkills}
            listUsers={listUsers}
            listShifts={listShifts}
            unCheckSelect={unCheckSelect}
            onSort={onSort}
            sort={sort}
          />
        </div>
      ) : (
        <Permission />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
  setting: state.setting,
});

export default connect(mapStateToProps, {
  getAgents,
  clearSuccess,
  updateUsers,
})(AgentSkills);
