import React from 'react';
import $ from 'jquery';
window.$ = $;

const DamageView = (props) => {
  let {
    damages,
    agentDamages,
    view,
    taskInspect,
    inspectionInspect,
    getDamages,
  } = props;
  const damage_table = inspectionInspect?.options?.damage_table ?? null;
  const checkBmw = damage_table ? damage_table.includes('bmw') : false;
  const deleteDamage = (id) => {
    props.setshowSvg();
    if (damages[id]) {
      damages.splice(id, 1);
      props.deleteDamage(damages);
    }
  };
  const deleteDamageAgent = (id) => {
    if (agentDamages[id]) {
      agentDamages[id].admin_response = 'reject';
      props.updateDamagesAgent(agentDamages);
    }
  };
  const revertDamagesAgent = (id) => {
    if (agentDamages[id]) {
      agentDamages[id].admin_response = 'revert';
      agentDamages[id].status = 'old';
      props.updateDamagesAgent(agentDamages);
    }
  };
  const callListDamages = (damages, id) => {
    let item = {
      component: damages[id].component,
      damage_group: damages[id].damage_group,
    };
    if (checkBmw) {
      item = {
        ...item,
        source: damage_table,
        series_nme: damages[id].series_nme,
      };
      getDamages(item, damage_table);
    } else if (damage_table === 'autograde') {
      getDamages(item, damage_table);
    } else {
      const damageSource =
        inspectionInspect &&
        inspectionInspect.options &&
        inspectionInspect.options.damages_source
          ? inspectionInspect.options.damages_source
          : false;
      item = {
        ...item,
        material: damages[id].material ? damages[id].material : 'null',
      };
      if (damageSource) {
        item = { ...item, source: damageSource };
      }
      getDamages(item);
    }
  };
  const checkDamageNotAction = (listDamage, classDamage, classRnd) => {
    for (let i = 0; i < listDamage.length; i++) {
      const dama = listDamage[i];
      const damageName = dama && dama.damage_name ? dama.damage_name : false;
      if (!damageName) {
        const pointRnd = document.getElementById(`${classDamage}-${i}`);
        const point = document.getElementById(`${classRnd}-${i}`);
        if (pointRnd && point) {
          pointRnd.classList.add('heartBeat');
          point.classList.add('heartBeat');
          setTimeout(() => {
            pointRnd.classList.remove('heartBeat');
            point.classList.remove('heartBeat');
          }, 1000);
        }
        return true;
      }
    }
    return false;
  };
  let editDamage = (id) => {
    let checkAction = false;
    if (damages && damages.length > 0) {
      checkAction = checkDamageNotAction(damages, 'damage-item', 'rnd');
      if (checkAction) {
        return;
      }
    }
    if (agentDamages && agentDamages.length > 0) {
      checkAction = checkDamageNotAction(
        agentDamages,
        'damage-item-agent',
        'rnd-agent',
      );
      if (checkAction) {
        return;
      }
    }
    damages[id].damage_name = null;
    props.updateDamage(damages);
    callListDamages(damages, id);
  };

  const editDamageAgent = (id) => {
    let checkAction = false;
    if (damages && damages.length > 0) {
      checkAction = checkDamageNotAction(damages, 'damage-item', 'rnd');
      if (checkAction) {
        return;
      }
    }
    if (agentDamages && agentDamages.length > 0) {
      checkAction = checkDamageNotAction(
        agentDamages,
        'damage-item-agent',
        'rnd-agent',
      );
      if (checkAction) {
        return;
      }
    }

    agentDamages[id].damage_typeAgent =
      agentDamages[id].damage_typeAgent || agentDamages[id].damage_name;
    agentDamages[id].damage_name = null;
    props.updateDamagesAgent(agentDamages);
    callListDamages(agentDamages, id);
  };
  let hightAgent = (index) => {
    $('#rnd-agent-' + index).addClass('heartBeat');
  };
  let dehighAgent = (index) => {
    $('#rnd-agent-' + index).removeClass('heartBeat');
  };
  let highlightDamage = (index) => {
    $('#rnd-' + index).addClass('heartBeat');
  };

  let dehighlightDamage = (index) => {
    $('#rnd-' + index).removeClass('heartBeat');
  };
  const rejectAllDamgesAgent = () => {
    const damages = agentDamages;
    for (let i = 0; i < damages.length; i++) {
      const element = damages[i];
      element.admin_response = 'reject';
    }
    props.updateDamagesAgent(damages);
  };
  let showDamagesAgent = () => {
    if (agentDamages && agentDamages.length > 0) {
      return (
        <div className="agent text-head mt-2">
          <div className="text mb-1">
            {agentDamages.length} Damage(s) Inspected
            <div
              style={{ fontSize: '16px', cursor: 'pointer' }}
              className={`badge badge-danger text-head ml-2 text-uppercase`}
              onClick={() => {
                rejectAllDamgesAgent(true);
              }}
            >
              Reject All
            </div>
          </div>
          <div
            style={{
              height: agentDamages.length > 4 ? '250px' : '',
              overflowY: agentDamages.length > 4 ? 'scroll' : '',
            }}
          >
            {agentDamages.map((item, index) => {
              const damage = item;
              let componentColor = 'secondary';
              switch (damage.damage_group) {
                case 'BODY':
                  componentColor = 'success';
                  break;
                case 'PART':
                case 'WHEEL':
                  componentColor = 'danger';
                  break;
                case 'GLASS':
                  componentColor = 'primary';
                  break;
                case 'TIRE':
                  componentColor = 'warning';
                  break;
                default:
                  break;
              }
              return (
                <li
                  key={`DamageView_${index}`}
                  className="list-group-item agent-damage"
                  onMouseEnter={() => hightAgent(index)}
                  onMouseLeave={() => dehighAgent(index)}
                >
                  <div
                    className={`reject-damage ${
                      item.admin_response === 'reject' ? 'show' : ''
                    }`}
                    id={`reject-${index}`}
                  >
                    <span style={{ width: '90%' }}>Rejected</span>
                  </div>
                  <div className="badge badge-dark text-head mr-1">
                    {index + 1}
                  </div>
                  {damage.material ? (
                    <div
                      style={{ color: '#fff' }}
                      className="badge badge-primary text-head text-uppercase mr-1"
                    >
                      {damage.material === 'Painted' ? (
                        <i className="fas fa-fill-drip"></i>
                      ) : damage.material === 'Textured Surface' ? (
                        <i className="fas fa-recycle"></i>
                      ) : damage.material === 'Chrome' ? (
                        <i className="far fa-gem"></i>
                      ) : damage.material === 'Alloy Wheel' ? (
                        <i className="fas fa-drum-steelpan"></i>
                      ) : damage.material === 'Plastic Cover' ? (
                        <i className="fas fa-record-vinyl"></i>
                      ) : damage.material === 'Steel Wheel' ? (
                        <i className="fas fa-dumbbell"></i>
                      ) : damage.material === 'Glass' ? (
                        <i className="fas fa-glass-whiskey"></i>
                      ) : damage.material === 'Steel' ? (
                        <i className="fas fa-dharmachakra"></i>
                      ) : null}
                    </div>
                  ) : null}
                  <div
                    className={`badge text-head badge-${componentColor} mr-1`}
                  >
                    {damage.component}
                  </div>
                  {damage.damage_typeAgent ? (
                    <div
                      id={`damage-item-agent-${index}`}
                      className="badge badge-warning text-head mr-1"
                    >
                      {damage.damage_typeAgent}[Agent]
                    </div>
                  ) : null}
                  <div
                    id={`damage-item-agent-${index}`}
                    className="badge badge-warning text-head mr-1"
                    style={{
                      color: damage.grade_score === 0 ? '#fff' : '#000',
                      background:
                        damage.grade_score === 0
                          ? 'grey'
                          : damage.grade_score === 1
                          ? 'yellow'
                          : damage.grade_score === 2
                          ? 'orange'
                          : damage.grade_score >= 3
                          ? 'red'
                          : '',
                    }}
                  >
                    {damage.damage_name || 'Not yet defined'}
                  </div>
                  {damage.status ? (
                    <div className="badge badge-warning text-head mr-1">
                      {damage.status}
                      {damage.status === 'repaired' ? (
                        <i
                          onClick={() => revertDamagesAgent(index)}
                          className="fa fas fa-times ml-2"
                        />
                      ) : null}
                    </div>
                  ) : null}
                  {!['02', '06', '15'].includes(taskInspect.photoCode) &&
                  damage.admin_response !== 'reject' ? (
                    <div
                      className="btn-group"
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      {damage.damage_name ? (
                        <i
                          onClick={() => editDamageAgent(index)}
                          className="fa fa-lg fa-edit text-warning mr-1"
                        />
                      ) : null}

                      <i
                        onClick={() => deleteDamageAgent(index)}
                        className="fa fa-lg fa-times text-danger"
                      />
                    </div>
                  ) : null}
                </li>
              );
            })}
          </div>
        </div>
      );
    }
  };
  let showDamagesList = () => {
    let width = '300px';
    if (view || agentDamages.length === 0) {
      width = '580px';
    }
    if (damages && damages.length > 0) {
      return (
        <div className="damages mt-2" style={{ position: 'relative' }}>
          <div
            className="count-damamge"
            style={{
              display: 'flex',
              alignItems: 'center',
              position: 'absolute',
              top: '-15px',
              left: '-10px',
              zIndex: '99',
            }}
          >
            <span
              className="text-white"
              style={{
                background: '#321342',
                width: '25px',
                height: '25px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '20%',
                fontSize: '13px',
              }}
            >
              {damages.length}
            </span>
          </div>
          <div
            style={{
              height: damages.length > 5 ? width : '',
              overflowY:
                (damages.length > 5 &&
                  agentDamages &&
                  agentDamages.length > 0) ||
                damages.length > 10
                  ? 'scroll'
                  : '',
            }}
          >
            {damages.map((item, index) => {
              const damage = item;
              let action = '';
              let componentColor = 'secondary';
              switch (damage.damage_group) {
                case 'BODY':
                  componentColor = 'success';
                  break;
                case 'PART':
                case 'WHEEL':
                  componentColor = 'danger';
                  break;
                case 'GLASS':
                  componentColor = 'primary';
                  break;
                case 'TIRE':
                  componentColor = 'warning';
                  break;
                default:
                  break;
              }
              switch (damage.admin_response) {
                case 'edit':
                  action = 'badge-primary';
                  break;
                case 'reject':
                  action = 'badge-danger';
                  break;
                case 'add':
                  action = 'badge-success';
                  break;
                default:
                  break;
              }
              return (
                <li
                  key={`DamageView_${index}`}
                  className={`list-group-item mb-1 `}
                  onMouseEnter={() => highlightDamage(index)}
                  onMouseLeave={() => dehighlightDamage(index)}
                  style={{
                    borderRadius: '10px',
                    opacity: damage.status === 'repaired' ? '0.3' : '1',
                  }}
                >
                  {damage.admin_response && (
                    <div className="d-block">
                      <div className="d-flex">
                        <div
                          className={`badge ${action} text-head text-white text-uppercase mr-1`}
                        >
                          Admin : {damage.admin_response}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="badge badge-dark text-head mr-1">
                    {index + 1}
                  </div>

                  {damage.material ? (
                    <div
                      style={{ color: '#fff' }}
                      className="badge badge-primary text-head text-uppercase mr-1"
                    >
                      {damage.material === 'Painted' ? (
                        <i className="fas fa-fill-drip"></i>
                      ) : damage.material === 'Textured Surface' ? (
                        <i className="fas fa-recycle"></i>
                      ) : damage.material === 'Chrome' ? (
                        <i className="far fa-gem"></i>
                      ) : damage.material === 'Alloy Wheel' ? (
                        <i className="fas fa-drum-steelpan"></i>
                      ) : damage.material === 'Plastic Cover' ? (
                        <i className="fas fa-record-vinyl"></i>
                      ) : damage.material === 'Steel Wheel' ? (
                        <i className="fas fa-dumbbell"></i>
                      ) : damage.material === 'Glass' ? (
                        <i className="fas fa-glass-whiskey"></i>
                      ) : damage.material === 'Steel' ? (
                        <i className="fas fa-dharmachakra"></i>
                      ) : null}
                    </div>
                  ) : null}

                  <div
                    id={`list-item-${index}`}
                    className={`badge badge-${componentColor} text-head mr-1`}
                  >
                    {damage.component || 'Not yet defined'}
                  </div>
                  {damage.damage_typeAgent ? (
                    <div
                      id={`damage-item-${index}`}
                      className="badge badge-warning text-head mr-1"
                    >
                      {damage.damage_typeAgent}[Agent]
                    </div>
                  ) : null}
                  <div
                    id={`damage-item-${index}`}
                    className="badge badge-warning text-head mr-1"
                    style={{
                      color: damage.grade_score === 0 ? '#fff' : '#000',
                      background:
                        damage.grade_score === 0
                          ? 'grey'
                          : damage.grade_score === 1
                          ? 'yellow'
                          : damage.grade_score === 2
                          ? 'orange'
                          : damage.grade_score >= 3
                          ? 'red'
                          : '',
                    }}
                  >
                    {damage.damage_name || 'Not yet defined'}
                  </div>

                  {!['02', '06', '15'].includes(taskInspect.photoCode) &&
                  view !== true ? (
                    <div
                      className="btn-group"
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      {damage.damage_name ? (
                        <i
                          onClick={() => editDamage(index)}
                          className="fa fa-lg fa-edit text-warning mr-1"
                        />
                      ) : null}

                      <i
                        onClick={() => deleteDamage(index)}
                        className="fa fa-lg fa-times text-danger"
                      />
                    </div>
                  ) : null}
                  {damage.status ? (
                    <div
                      id={`list-item-${index}`}
                      className={`badge badge-${
                        damage.status === 'repaired' ? 'warning' : 'primary'
                      } text-head mr-1`}
                    >
                      {damage.status}
                    </div>
                  ) : null}
                </li>
              );
            })}
          </div>
        </div>
      );
    } else {
      return null;
    }
  };
  return (
    <div className="damage-view">
      {showDamagesList()}
      {showDamagesAgent()}
    </div>
  );
};

export default DamageView;
