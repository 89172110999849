import React from 'react';
import DetailTask from './DetailTask';

const Progress = (props) => {
  const {
    tasks,
    inspection,
    user,
    rejectTask,
    vehicleData,
    reportImage,
    acceptTask,
    photosSetting,
    resetInspect,
    validatePhoto,
    setshowValidateSuccess,
  } = props;
  let ppWarning = [];
  let showSttPhoto = [];
  let ppCheckRule = [];
  let showPhotoSkip = [];
  let photoExist = [];
  const qcProgress = inspection.qcProgress ? inspection.qcProgress : [];
  const qc_on_having_odometer =
    inspection?.options?.qc_on_having_odometer ?? false;
  const capture_photo_codes =
    inspection?.options?.customized?.capture_photo_codes ?? [];
  const vehicle = inspection?.vehicle ?? null;
  const dataVehicle = vehicle?.data ?? null;
  const task03 = tasks.find((task) => task.photoCode === '03');
  let showUserInput = false;
  if (task03) {
    if (
      qc_on_having_odometer &&
      dataVehicle &&
      dataVehicle.odom_unit &&
      dataVehicle.odom_reading &&
      dataVehicle.odom_reading !== '0'
    ) {
      showUserInput = true;
    }
  }
  let perQc = 0;
  let qcPassed = inspection?.qcPassed ?? false;

  if (qcProgress && qcProgress.length) {
    perQc = ['INSPECTED', 'CONFIRMED', 'FINISHED', 'COMPLETED'].includes(
      inspection.status,
    )
      ? 100
      : Math.ceil((qcProgress.length * 100) / 13);
  }
  let listSteps = [
    { step: 1, photoCode: '01', svg: '/img/outline/vin.svg', show: false },
    {
      step: 2,
      photoCode: '02',
      svg: '/img/outline/interior.svg',
      show: false,
    },
    { step: 3, photoCode: '03', svg: '/img/outline/odo.svg', show: false },
    { step: 4, photoCode: '04', svg: '/img/outline/left.svg', show: false },
    { step: 5, photoCode: '05', svg: '/img/outline/front.svg', show: false },
    {
      step: 6,
      photoCode: '06',
      svg: '/img/outline/tireFront.svg',
      show: false,
    },
    { step: 7, photoCode: '07', svg: '/img/outline/right.svg', show: false },
    { step: 8, photoCode: '08', svg: '/img/outline/rear.svg', show: false },
    { step: 9, photoCode: '09', svg: '/img/outline/glass.svg', show: false },
    { step: 10, photoCode: '10', svg: '/img/outline/10.svg', show: false },
    { step: 11, photoCode: '11', svg: '/img/outline/11.svg', show: false },
    { step: 12, photoCode: '12', svg: '/img/outline/12.svg', show: false },
    { step: 13, photoCode: '13', svg: '/img/outline/13.svg', show: false },
    {
      step: 14,
      photoCode: '15',
      svg: '/img/outline/tireRear.svg',
      show: false,
    },
    //{ step: 15, des: 'Review Session', show: false },
  ];

  let listImages = [
    { photoCode: '01' },
    { photoCode: '02' },
    { photoCode: '03' },
    { photoCode: '04' },
    { photoCode: '05' },
    { photoCode: '06' },
    { photoCode: '07' },
    { photoCode: '08' },
    { photoCode: '09' },
    { photoCode: '10' },
    { photoCode: '11' },
    { photoCode: '12' },
    { photoCode: '13' },
    { photoCode: '15' },
  ];
  if (capture_photo_codes.length > 0) {
    listImages = capture_photo_codes.map((item) => {
      item = item < 10 ? `0${item}` : `${item}`;
      return {
        photoCode: item,
      };
    });
    listSteps = listSteps.filter(
      (step) =>
        [1, 11].includes(step.step) ||
        listImages.some((image) => image.photoCode === step.photoCode),
    );
  }
  for (let i = 0; i < tasks.length; i++) {
    const task = tasks[i];
    if (task.status !== 'REJECTED') {
      photoExist.push(task.photoCode);
    }
    // Flow Check Validating Photo before PROCESSING
    const hitl = inspection?.hitl ?? [];
    //
    if (['BAD', 'ACCEPTED'].includes(task.statusImg)) {
      const foundTaskIndex = listImages.findIndex(
        (item) => item.photoCode === task.photoCode,
      );
      if (foundTaskIndex || foundTaskIndex === 0) {
        let dataHitl = {};
        if (hitl) {
          const foundHitl = hitl.find(
            (itemHitl) => `0${itemHitl.photo_code}` === task.photoCode,
          );
          if (foundHitl) {
            dataHitl = {
              ...foundHitl,
            };
          }
        }
        listImages[foundTaskIndex] = {
          ...task,
          dataHitl,
        };
      }
    }
  }
  if (photoExist && photoExist.length > 0) {
    let photoDefault = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
    ];
    if (capture_photo_codes.length > 0) {
      photoDefault = capture_photo_codes.map((item) => {
        if (item > 9) {
          return `${item}`;
        } else {
          return `0${item}`;
        }
      });
    }
    const customized = inspection.options?.customized ?? null;
    const skipTire = inspection.options?.skip_tire ?? false;
    let taskSkip = [];
    if (
      customized &&
      customized.allow_skip &&
      customized.allow_skip.length > 0
    ) {
      for (let i = 0; i < customized.allow_skip.length; i++) {
        const photoCode = customized.allow_skip[i];
        taskSkip.push(photoCode);
      }
    }
    if (skipTire) {
      taskSkip.push('06');
    }
    if (customized) {
      if (customized.skip_vin) {
        taskSkip.push('01');
      }
      if (customized.skip_interior) {
        taskSkip.push('02');
      }
    }
    taskSkip.sort((a, b) => {
      return a - b;
    });
    let photoMissing = [];
    photoMissing = photoDefault.filter(
      (item) => !photoExist.includes(item) && !taskSkip.includes(item),
    );
    photoMissing.sort((a, b) => {
      return a - b;
    });
    if (taskSkip && taskSkip.length) {
      showPhotoSkip.push(
        <div
          key="Photo_Skip"
          className="badge badge-success"
          style={{
            fontWeight: 'bold',
          }}
        >
          <span className="text-white">Skip</span>
        </div>,
      );
      showPhotoSkip.push(
        <div key="Photo_SkipTask" className="badge badge-light">
          <span className="text-dark">{taskSkip.join(',')}</span>
        </div>,
      );
    }
    if (photoMissing && photoMissing.length > 0) {
      showSttPhoto.push(
        <div
          key="Missing_Reject"
          className="badge badge-warning"
          style={{
            fontWeight: 'bold',
          }}
        >
          <span className="text-dark">Rejected Or Missing</span>
        </div>,
      );
      showSttPhoto.push(
        <div
          key="Photo_Missing_Reject"
          className="badge badge-light"
          style={{
            fontWeight: 'bold',
          }}
        >
          <span className="text-dark">{photoMissing.join(',')}</span>
        </div>,
      );
    }
  }

  return (
    <div id="Inspection_Progress">
      <div
        className="information_image mt-2"
        style={{
          borderRadius: '30px',
          padding: '15px 25px',
          background: '#675d7a',
        }}
      >
        <div
          className="text-head text-white mb-2 d-inline-block"
          style={{ width: '225px' }}
        >
          <div className="d-flex align-items-center">
            {!inspection.qcPassed ? (
              <div className="badge badge-primary">QC</div>
            ) : (
              <div className="badge badge-success">QC PASSED</div>
            )}
            <div
              className="progress"
              id={`qc_detail_${inspection.sessionID}`}
              style={{ cursor: 'pointer', margin: '0 5px', width: '80%' }}
            >
              <div
                className={`progress-bar ${
                  perQc >= 100 ||
                  ['INSPECTED', 'CONFIRMED', 'FINISHED', 'COMPLETED'].includes(
                    inspection.status,
                  )
                    ? 'bg-success'
                    : 'bg-primary progress-bar-striped progress-bar-animated'
                }`}
                role="progressbar"
                style={{
                  width: `${perQc}%`,
                  fontWeight: 'bold',
                }}
                aria-valuenow={perQc}
                aria-valuemin="0"
                aria-valuemax={perQc}
              >
                {qcProgress && qcProgress.length ? qcProgress.length : 0}
              </div>
            </div>
          </div>
        </div>
        {showUserInput ? (
          <div className="text-head text-white mb-1">
            <div className="d-flex align-items-center">
              <div className="badge badge-danger mr-1 text-uppercase">
                User Input
              </div>
              <div className="badge badge-light mr-1 text-uppercase text-head">
                <span className="mr-1"> Odometer :</span>
                <span className="text-dark">{dataVehicle.odom_reading}</span>
              </div>

              <div className="badge badge-light mr-1 text-uppercase text-head">
                <span className="mr-1"> Unit :</span>
                <span className="text-dark">{dataVehicle.odom_unit}</span>
              </div>
            </div>
          </div>
        ) : null}
        {ppWarning.length > 0 && (
          <div className="badge-group">
            {ppWarning}
            {ppCheckRule}
          </div>
        )}

        {showSttPhoto.length > 0 && (
          <div className="badge-group">{showSttPhoto}</div>
        )}
        {showPhotoSkip.length > 0 && (
          <div className="badge-group">{showPhotoSkip}</div>
        )}
      </div>

      <div className="information_inspect mt-2">
        <DetailTask
          tasks={tasks}
          qcPassed={qcPassed}
          listImages={listImages}
          listSteps={listSteps}
          inspection={inspection}
          photosSetting={photosSetting}
          user={user}
          reportImage={reportImage}
          rejectTask={rejectTask}
          acceptTask={acceptTask}
          resetInspect={resetInspect}
          validatePhoto={validatePhoto}
          setshowValidateSuccess={setshowValidateSuccess}
          vehicleData={vehicleData}
        />
      </div>
    </div>
  );
};

export default Progress;
