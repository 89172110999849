import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { SettingOption } from './components';
import { setSetting, setPassword, setNotification } from '../operations';
import Permission from '../../ui/containers/Permission';

const Setting = (props) => {
  const [timezone, settimezone] = useState('');
  const [passwordNew, setpasswordNew] = useState('');
  const [passwordRepeat, setpasswordRepeat] = useState('');
  const [allowChange, setallowChange] = useState(false);
  const [notification, setnotification] = useState('');
  const { auth } = props;
  const roleUser = auth && auth.user && auth.user.role ? auth.user.role : '';
  const username =
    auth && auth.user && auth.user.username ? auth.user.username : false;
  const options = auth && auth.user.options ? auth.user.options : false;

  const timeZoneOptions = [
    { value: 'America/Toronto', label: 'America/Toronto' },
    { value: 'Asia/Ho_Chi_Minh', label: 'Asia/Ho_Chi_Minh' },
  ];
  useEffect(() => {
    if (options.timezone) {
      const timezone = options.timezone ? options.timezone : 'Asia/Ho_Chi_Minh';
      settimezone(timezone);
    }
    // eslint-disable-next-line
  }, []);

  const onChange = (e) => {
    settimezone(e.target.value);
    onSetSetting(e.target.name, e.target.value);
  };

  const onSetSetting = (key, value) => {
    const data = {
      [key]: value,
    };
    props.setSetting(data);
  };

  const onChangePassword = (e) => {
    e.target.name;
    if (e.target.name === 'passwordNew') {
      setpasswordNew(e.target.value);
    }
    if (e.target.name === 'passwordRepeat') {
      setpasswordRepeat(e.target.value);
    }
    validatePassword();
  };
  const onChangeText = (e) => {
    setnotification(e.target.value);
  };
  const updateNoti = () => {
    let data = {};
    data['notification'] = notification;
    props.setNotification(data);
  };
  const validatePassword = () => {
    const passwordNew = document.getElementById('Password_new').value;
    const passwordRepeat = document.getElementById('Password_repeat').value;
    let allowChange = true;
    if (passwordNew.length < 6) {
      onValid('length', false);
      allowChange = false;
    } else {
      onValid('length', true);
    }
    if (passwordNew !== passwordRepeat) {
      onValid('match', false);
      allowChange = false;
    } else {
      onValid('match', true);
    }
    setallowChange(allowChange);
  };

  const onValid = (key, status) => {
    let statusClass, iconClass;
    if (status) {
      statusClass = 'small text-success';
      iconClass = 'fa fa-fw fa-check text-success mr-2';
    } else {
      statusClass = 'small text-danger';
      iconClass = 'fa fa-fw fa-times text-danger mr-2';
    }
    const el = document.getElementById('Validation_' + key);
    const elIcon = document.getElementById('Validation_' + key + '_icon');
    if (el) {
      el.className = statusClass;
    }
    if (elIcon) {
      elIcon.className = iconClass;
    }
  };

  const onSetPassword = () => {
    if (allowChange) {
      const data = {
        passwordNew,
        passwordRepeat,
      };
      props.setPassword(data);
    }
  };

  return roleUser === 'VIEWER' ? (
    <Permission />
  ) : (
    <div id="Settings">
      <div className="container-fluid">
        <h2 className="subtitle">Settings</h2>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-lg-4">
                <SettingOption
                  id="timezone"
                  label="Timezone"
                  description="All timestamps in application will display in this timezone."
                  type="select"
                  options={timeZoneOptions}
                  value={timezone}
                  onChange={(e) => onChange(e)}
                ></SettingOption>
              </div>
            </div>
            <hr></hr>
            <h2 className="display-5 text-logan mb-0">Change Password</h2>
            <div className="small text-muted mb-3">
              Please enter a new password in the fields below.
            </div>
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="Password_new" className="text-head">
                        New Password
                      </label>
                      <input
                        id="Password_new"
                        type="password"
                        name="passwordNew"
                        onChange={onChangePassword}
                        className="form-control"
                        value={passwordNew}
                      ></input>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="Password_repeat" className="text-head">
                        Confirm New Password
                      </label>
                      <input
                        id="Password_repeat"
                        type="password"
                        name="passwordRepeat"
                        onChange={onChangePassword}
                        className="form-control"
                        value={passwordRepeat}
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 end">
                <div id="Validation_length" className="small">
                  <i
                    id="Validation_length_icon"
                    className="fa fa-fw fa-minus text-muted mr-2"
                  ></i>
                  Password must be at least 6 characters long.
                </div>
                <div id="Validation_match" className="small">
                  <i
                    id="Validation_match_icon"
                    className="fa fa-fw fa-minus text-muted mr-2"
                  ></i>
                  New password and confirm password must match.
                </div>
              </div>
            </div>
            <button
              className="btn btn-success mt-2"
              disabled={!allowChange}
              onClick={onSetPassword}
            >
              Set Password
            </button>
            <hr></hr>
            {username === 'tuan.admin' ? (
              <div>
                <h2 className="display-5 text-logan mb-0">
                  Change Notification
                </h2>
                <div className="small text-muted mb-3">
                  Please enter a new notification down here.
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            rows={3}
                            name="notification"
                            onChange={(e) => onChangeText(e)}
                            value={notification}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button className="btn btn-success mt-2" onClick={updateNoti}>
                  Update
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  setting: state.setting,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  setSetting,
  setPassword,
  setNotification,
})(Setting);
