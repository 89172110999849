import React from 'react';
//import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { persistor, store } from './store';
import { PersistGate } from 'redux-persist/lib/integration/react';
import ErrorBoundary from './ErrorBoundary';
import PrivateRoute from './components/common/PrivateRoute';
import { createBrowserHistory } from 'history';
import { Auth } from './app/auth';
import { Cage } from './app/cages';
import { Dashboard } from './app/dashboard';
import { Inspection } from './app/inspections';
import { User } from './app/users';
import { UI } from './app/ui';
import { Setting } from './app/settings';
import { TaskFailed } from './app/taskFailed';
import './App.scss';
// Check for token
import authActions from './app/auth/actions';
import { setAuthToken, logout } from './app/auth/operations';
const history = createBrowserHistory();
if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(authActions.setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logout());
    // Redirect to login
    window.location.href = '/';
  }
}
// window.onbeforeunload = async () => {
//   await store.dispatch(logout());
// };
const App = () => {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router history={history}>
            <div className="App">
              <UI.Alert />
              <UI.Loading></UI.Loading>
              <Route exact path="/" component={Auth.Login} />
              <PrivateRoute component={UI.Sidebar} history={history} />
              <section id="Workspace">
                {/* <UI.Toolbar></UI.Toolbar> */}
                <div id="Routes" className="routes">
                  <Switch>
                    <Route exact path="/dashboard" component={Dashboard} />
                    <Route exact path="/agents" component={User.List} />
                    <Route exact path="/agentSkills" component={User.Skills} />
                    <Route exact path="/agentCreate" component={User.New} />
                    <Route exact path="/agentView/:id" component={User.View} />
                    <Route
                      exact
                      path="/agentStats/:id"
                      component={User.Stats}
                    />

                    <Route
                      exact
                      path="/inspections"
                      component={Inspection.List}
                    />
                    <Route
                      exact
                      path="/inspect"
                      component={Inspection.Inspect}
                    />
                    <Route
                      exact
                      path="/inspectionView/:id"
                      component={Inspection.View}
                    />
                    <Route
                      exact
                      path="/performance"
                      component={TaskFailed.Performance}
                    />
                    <Route
                      exact
                      path="/performance/listTaskFailed"
                      component={TaskFailed.List}
                    />
                    <Route
                      exact
                      path="/performance/listTaskFailed/:id"
                      component={TaskFailed.View}
                    />
                    <Route
                      exact
                      path="/performance/statistic"
                      component={TaskFailed.Statistic}
                    />
                    <Route exact path="/cages" component={Cage.List} />

                    <Route exact path="/cageView/:id" component={Cage.View} />
                    <Route
                      exact
                      path="/cageLabel/:id"
                      component={Cage.CageLabel}
                    />
                    <Route exact path="/cageCreate/:id?" component={Cage.New} />
                    <Route exact path="/settings" component={Setting} />

                    <Route path="/forbidden" component={UI.Forbidden} />

                    <Route path="/not-found" component={UI.NotFound} />
                  </Switch>
                </div>
              </section>
            </div>
          </Router>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
};
export default App;
