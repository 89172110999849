import React from 'react';
import { Link } from 'react-router-dom';
const UsetItemSkills = ({ data, onCheckSelect, listUsers }) => {
  const checkAdded = listUsers.findIndex((v) => v === data.username);
  let groups = ['DEFAULT', 'AMZ', 'AI-EXP'];
  const skills = ['AI'];
  if (process.env.REACT_APP_APP_ENV === 'development') {
    groups = [
      'DEFAULT',
      'AMZ',
      'AI-EXP',
      'AMZ-1',
      'AMZ-2',
      'AMZ-3',
      'DEF-1',
      'DEF-2',
      'DEF-3',
    ];
  }
  return (
    <div
      style={{
        marginBottom: '5px',
        border: '1px solid #fff',
        display: 'flex',
        width: '100%',
        background: '#fff',
        borderRadius: '7px',
      }}
    >
      <div
        style={{
          width: '5%',
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '10px',
          fontWeight: 'bold',
        }}
      >
        {checkAdded !== -1 ? (
          <i
            style={{ fontSize: '28px' }}
            className="fas fa-check-square text-success"
          ></i>
        ) : (
          <i
            style={{ cursor: 'pointer', fontSize: '28px' }}
            onClick={() => onCheckSelect(data.username)}
            className="fas fa-plus text-dark"
          ></i>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '12%',
        }}
      >
        <Link to={`/agentView/${data._id}`} className="text-head text-primary">
          {data.username}
        </Link>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '6%',
        }}
      >
        {data.shift ? (
          <div className="badge badge-dark text-uppercase text-head display-5">
            {data.shift}
          </div>
        ) : (
          ''
        )}
      </div>
      <div
        className="row"
        style={{
          width: '15%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {groups.map((s, i) => {
          return (
            <div key={`Group_${i}`} className="col-lg-6">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={`Group_${s}_${data._id}`}
                  name="groups[]"
                  disabled={true}
                  value={s}
                  checked={data.groups && data.groups.includes(s)}
                />
                <label
                  className="custom-control-label text-xsm text-uppercase"
                  htmlFor={`Group_${s}_${data._id}`}
                >
                  {s.toUpperCase()}
                </label>
              </div>
            </div>
          );
        })}
      </div>
      <div
        className="row container-fluid"
        style={{
          width: '62%',
          // display: 'flex',
          // alignItems: 'center',
        }}
      >
        {skills.map((s, i) => {
          return (
            <div key={`Skill_${i}`} className="col-3">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={`Skill_${s}`}
                  name="skills[]"
                  value={s}
                  disabled={true}
                  checked={data.skills && data.skills.includes(s)}
                />
                <label
                  className="custom-control-label text-xsm text-uppercase"
                  htmlFor={`Skill_${s}`}
                >
                  {s.toUpperCase()}
                </label>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UsetItemSkills;
