import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

const UserListTableItem = ({
  data,
  timezone,
  putAgent,
  role,
  showModalConfirm,
}) => {
  let groups = ['DEFAULT', 'AMZ', 'AI-EXP'];
  if (process.env.REACT_APP_APP_ENV === 'development') {
    groups = [
      'DEFAULT',
      'AMZ',
      'AI-EXP',
      'AMZ-1',
      'AMZ-2',
      'AMZ-3',
      'DEF-1',
      'DEF-2',
      'DEF-3',
    ];
  }
  const newTab = (id) => {
    const link = window.location.origin;
    let url = link + `/inspectionView/${id}`;
    const win = window.open(url, '_blank');
    win.focus();
  };
  const onCheckGroups = (e) => {
    const value = e.target.value;
    let newGroups = [];
    if (data.groups && data.groups.includes(value)) {
      newGroups = data.groups.filter((itemGroup) => itemGroup !== value);
    } else {
      newGroups = [...data.groups, value];
    }
    const dataUpdate = {
      username: data.username,
      email: data.email,
      last: data.last,
      first: data.first,
      groups: newGroups,
    };
    putAgent(dataUpdate, data._id);
  };
  const updateActionAt = data.updateActionAt
    ? moment(data.updateActionAt).tz(timezone).format('DD/MM/YY hh:mm:ss A')
    : null;
  const status = () => {
    let icon;
    let backGround = '';
    let color = '';
    let status = '';
    switch (data.action) {
      case 'Online':
        backGround = '#2ecc71';
        icon = <i className="fas fa-grin-wink"></i>;
        color = 'white';
        status = 'On';
        break;
      case 'Login':
        backGround = '#2ecc71';
        icon = <i className="fas fa-grin-wink"></i>;
        color = 'white';
        status = 'On';
        break;
      case 'Waiting':
        backGround = '#3498db';
        icon = <i className="fas fa-piggy-bank"></i>;
        color = 'white';
        status = 'Waiting';
        break;
      case 'Inspecting':
        backGround = '#f1c40f';
        icon = <i className="fas fa-running"></i>;
        color = 'white';
        status = 'Inspect';
        break;
      case 'Checking':
        backGround = '#f1c40f';
        icon = <i className="fas fa-search"></i>;
        color = 'white';
        status = 'Checking';
        break;
      case 'Validating':
        backGround = '#f1c40f';
        icon = <i className="fas fa-search"></i>;
        color = 'white';
        status = 'Checking';
        break;
      default:
        backGround = '#95a5a6';
        icon = <i className="fas fa-bed"></i>;
        color = 'white';
        status = 'Off';
        break;
    }
    return (
      <div className="status">
        <div
          style={{
            background: backGround,
            color: color,
            display: 'flex',
            fontSize: '17px',
            padding: '8px 12px',
            fontWeight: 'bold',
          }}
          className="badge text-uppercase"
        >
          {icon}
          <div className="status ml-2">{status ? status : 'Off'}</div>
        </div>
      </div>
    );
  };
  return (
    <div
      style={{
        marginBottom: '5px',
        border: '1px solid #fff',
        display: 'flex',
        width: '100%',
        minHeight: '56px',
        background: '#fff',
        borderRadius: '7px',
      }}
    >
      <div
        style={{
          width: '8%',
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '10px',
          fontWeight: 'bold',
        }}
      >
        {data.role}
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '12%',
        }}
      >
        <Link to={`/agentView/${data._id}`} className="text-head text-primary">
          {data.username}
        </Link>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '5%',
        }}
      >
        {data.shift ? (
          <div className="badge badge-dark text-uppercase text-head display-5">
            {data.shift}
          </div>
        ) : null}
      </div>
      <div
        style={{
          width: '15%',
          display: 'flex',
          alignItems: 'center',
          fontWeight: 'bold',
        }}
      >
        {status()}
      </div>
      <div
        style={{
          width: '20%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {data.lastTaskDone ? (
          <div>
            {data.lastTaskDone.photoCode && (
              <div className="btn btn-tiny btn-warning mr-1" disabled>
                <span className="text-sm">{data.lastTaskDone.photoCode}</span>
              </div>
            )}
            <div
              style={{
                background: '#2ecc71',
                padding: '6px 10px',
                fontWeight: 'bold',
                fontSize: '14px',
              }}
              className="badge badge-success text-uppercase mr-2"
            >
              {data.lastTaskDone.inspectName}
            </div>
            <span
              style={{
                fontWeight: 'bold',
                cursor: 'pointer',
              }}
              onClick={() => newTab(data.lastTaskDone.sessionID)}
              className="text-primary"
            >
              {data.lastTaskDone.sessionID}
            </span>
          </div>
        ) : null}
      </div>
      <div
        style={{
          width: '22%',
          alignItems: 'center',
        }}
        className="row container-fluid"
      >
        {groups.map((s, i) => {
          return (
            <div
              key={`Group_${i}`}
              className="custom-control custom-checkbox col-4"
            >
              <input
                type="checkbox"
                className="custom-control-input"
                id={`Group_${s}_${data._id}`}
                name="groups[]"
                disabled={true}
                value={s}
                checked={data.groups && data.groups.includes(s)}
                onChange={(e) => onCheckGroups(e, data._id)}
              />
              <label
                className="custom-control-label text-xsm text-uppercase"
                htmlFor={`Group_${s}_${data._id}`}
              >
                {s.toUpperCase()}
              </label>
            </div>
          );
        })}
      </div>
      <div
        style={{
          width: '15%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {updateActionAt}
      </div>
      {['SUPER', 'TRAINER'].includes(role) ? (
        <div
          style={{
            width: '3%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            className="mr-1 mb-1"
            style={{
              padding: '3px 7px',
              borderRadius: '5px',
              background: 'red',
              cursor: 'pointer',
              display: 'inline-block',
            }}
            onClick={() => showModalConfirm(data._id, data.username)}
          >
            <i className="fas fa-times text-white"></i>
          </div>
        </div>
      ) : null}
    </div>
  );
};

UserListTableItem.propTypes = {
  data: PropTypes.object.isRequired,
};

export default UserListTableItem;
