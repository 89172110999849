import React from 'react';
const RejectData = (props) => {
  const {
    taskRejected,
    statusInspection,
    inspection,
    user,
    showReject,
    onAcceptTask,
    showModalFinal,
  } = props;

  const qc_only_accept =
    inspection && inspection.options && inspection.options.qc_only_accept
      ? inspection.options.qc_only_accept
      : null;
  const inspector = inspection.inspector ? inspection.inspector : '';
  const qa = inspection.qa ? inspection.qa : '';
  const manager = inspection.manager ? inspection.manager : '';
  // const group = inspection.group ? inspection.group : 'DEFAULT';
  const role = user.role ? user.role : 'AGENT';
  const username = user.username ? user.username : '';
  let checkShowButton = false;
  if (['QA', 'AGENT'].includes(role)) {
    if (username === inspector) {
      checkShowButton = true;
    }
    if (username === qa) {
      checkShowButton = true;
    }
  }
  if (['SUPER', 'TRAINER', 'ADMIN'].includes(role)) {
    if (username === manager) {
      checkShowButton = true;
    }
  }
  const checkSkipQC =
    inspection &&
    inspection.options &&
    inspection.options.customized &&
    inspection.options.customized.custom_skip_qc &&
    inspection.options.customized.custom_skip_qc.length > 0
      ? inspection.options.customized.custom_skip_qc.map((item) => {
          if (item < 10) {
            return `0${item}`;
          } else {
            return `${item}`;
          }
        })
      : [];
  return (
    taskRejected &&
    taskRejected.length > 0 &&
    taskRejected.map((task, i) => {
      let message = Object.keys(task.message || {});
      if (message.length > 0) {
        message = message[0].replace(/_/g, ' ');
        message = message.charAt(0).toUpperCase() + message.slice(1);
      }
      return (
        <div
          key={`rejected_` + i}
          className="mr-2 mb-1 mt-2"
          style={{
            display: 'inline-block',
          }}
        >
          <div
            className="photocode_reject "
            style={{
              display: 'flex',
            }}
          >
            <div
              className="group"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '5px',
              }}
            >
              <div className="image">
                <div
                  className="btn-group"
                  style={{
                    width: '100%',
                    background: '#32164017',
                    borderTopLeftRadius: '5px',
                    borderTopRightRadius: '5px',
                  }}
                >
                  <button
                    className="btn btn-tiny btn-light"
                    onClick={() => {
                      showModalFinal(task, `${task.image_cap}.webp`);
                    }}
                  >
                    <span
                      style={{
                        fontSize: '12px',
                        textAlign: 'center',
                      }}
                    >
                      Webp
                    </span>
                  </button>
                  <button
                    className="btn btn-tiny btn-light"
                    onClick={() => {
                      showModalFinal(task, task.image_cap);
                    }}
                  >
                    <span
                      style={{
                        fontSize: '12px',
                        textAlign: 'center',
                      }}
                    >
                      Img
                    </span>
                  </button>
                </div>
                <div className="photoId badge badge-dark text-head d-block">
                  {task.photo_id}
                </div>
              </div>

              <div className="edit ml-1">
                {task.qc && (
                  <div className="username badge badge-warning text-head d-block">
                    {task.qc}
                  </div>
                )}
                <div className="status mt-1" style={{ display: 'flex' }}>
                  <div
                    className="badge badge-danger"
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {task.status}
                  </div>
                  {checkShowButton &&
                    !checkSkipQC.includes(task.photoCode) &&
                    [('ACCEPTED', 'FINISHED', 'REJECTED')].includes(
                      task.status,
                    ) &&
                    !['TRAINEE', 'VIEWER'].includes(user.role) &&
                    !qc_only_accept &&
                    ![
                      'INSPECTED',
                      'CONFIRMED',
                      'FINISHED',
                      'COMPLETED',
                    ].includes(statusInspection) && (
                      <i
                        title="Reject This Image"
                        className="fa fa-ban ml-1"
                        style={{
                          fontSize: '13px',
                          padding: '3px',
                          color: '#fff',
                          background: '#dc3545',
                          borderRadius: '5px',
                          cursor: 'pointer',
                        }}
                        onClick={() => showReject(task.photoCode, task._id)}
                      />
                    )}
                  {checkShowButton &&
                    !['TRAINEE', 'VIEWER'].includes(user.role) &&
                    !['FINISHED', 'COMPLETED'].includes(statusInspection) &&
                    ['REJECTED'].includes(task.status) && (
                      <i
                        style={{
                          fontSize: '13px',
                          padding: '3px',
                          color: '#fff',
                          background: 'green',
                          borderRadius: '5px',
                          cursor: 'pointer',
                        }}
                        title="Accept This Image"
                        className="fa fa-check ml-1"
                        onClick={() => onAcceptTask(task._id)}
                      />
                    )}
                </div>
              </div>
            </div>
            <div
              className="ml-2"
              style={{
                fontWeight: 'bold',
                fontSize: '14px',
              }}
            >
              {message ? message : ''}
            </div>
          </div>
        </div>
      );
    })
  );
};

export default RejectData;
