export const GET_INSPECTION = 'app/inspections/GET_INSPECTION';
export const GET_INSPECTIONS_LEFT = 'app/inspections/GET_INSPECTIONS_LEFT';
export const GET_INSPECTIONS_RIGHT = 'app/inspections/GET_INSPECTIONS_RIGHT';
export const START_WORK = 'app/inspections/START_WORK';
export const STOP_WORK = 'app/inspections/STOP_WORK';
export const START_VALIDATE = 'app/inspections/START_VALIDATE';
export const STOP_VALIDATE = 'app/inspections/STOP_VALIDATE';
export const GET_ACTIVE_CAGES = 'app/inspections/GET_ACTIVE_CAGES';
export const PUT_INSPECTION_CAGE = 'app/inspections/PUT_INSPECTION_CAGE';
export const COMPLETE_INSPECTION = 'app/inspections/COMPLETE_INSPECTION';
export const REJECT_TASK = 'app/inspections/REJECT_TASK';
export const KICK_AGENT = 'app/inspections/KICK_AGENT';
export const ACCEPT_TASK = 'app/inspections/ACCEPT_TASK';
export const CREATE_TASKSLOT = 'app/inspections/CREATE_TASKSLOT';
export const GET_PROCESS_PHOTO = 'app/inspections/GET_PROCESS_PHOTO';
export const CHANGE_STT_INSPECTION = 'app/inspections/CHANGE_STT_INSPECTION';
export const UPDATE_VEHICLE = 'app/inspections/UPDATE_VEHICLE';
export const CREATE_TRAINING = 'app/inspections/CREATE_TRAINING';
export const INSPECT_INPSPECTION = 'app/inspections/INSPECT_INPSPECTION';
export const CHECK_INSPECTION = 'app/inspections/CHECK_INSPECTION';
export const CROSS_CHECK_INSPECTION = 'app/inspections/CROSS_CHECK_INSPECTION';
export const MANAGE_INSPECTION = 'app/inspections/MANAGE_INSPECTION';
export const HOLDING = 'app/inspections/HOLDING';
export const SKIP_VIN = 'app/inspections/SKIP_VIN';
export const INVALID = 'app/inspections/INVALID';
export const QCPASSED = 'app/inspections/QCPASSED';
export const ADMIN_SET_STANDARD = 'app/inspections/ADMIN_SET_STANDARD';
export const CLEAR_INSPECTION = 'app/inspections/CLEAR_INSPECTION';
export const CLEAR_SUCCESS = 'app/inspections/CLEAR_SUCCESS';
export const INSPECT_ALL = 'app/inspections/INSPECT_ALL';
export const GET_ADMIN_ACTION = 'app/inspections/GET_ADMIN_ACTION';
export const GET_SESSION_RD = 'app/inspections/GET_SESSION_RD';
export const REPORT_IMAGE = 'app/inspections/REPORT_IMAGE';
export const VALIDATE_PHOTO = 'app/inspections/VALIDATE_PHOTO';
export const VEHICLE_DATA = 'app/inspections/VEHICLE_DATA';
