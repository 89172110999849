import React from 'react';
import PrismaZoom from 'react-prismazoom';
const ModalImageFull = (props) => {
  let { src, photoCode } = props;

  let checkSupport = [];
  switch (photoCode) {
    case '04':
      checkSupport = ['10', '13'];
      break;
    case '05':
      checkSupport = ['10', '11'];
      break;
    case '07':
      checkSupport = ['11', '12'];
      break;
    case '08':
      checkSupport = ['12', '13'];
      break;
    default:
      break;
  }

  let showImg = () => {
    if (src && src.length > 0) {
      const srcSupportEx = [];
      if (checkSupport && checkSupport.length > 0) {
        for (let i = 0; i < src.length; i++) {
          const item = src[i];
          for (let k = 0; k < checkSupport.length; k++) {
            const itemCheck = checkSupport[k];
            if (item.includes(`capture/${itemCheck}`)) {
              srcSupportEx.push(item);
            }
          }
        }
        src = srcSupportEx;
      }

      return (
        <div className="modal-dialog modal-full">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Support View</h4>
            </div>
            <div className="modal-body">
              <div className="group-img">
                {src.map((item, index) => {
                  return (
                    <div className="item-img" key={`item-` + index}>
                      <PrismaZoom
                        className="App-zoom"
                        minZoom={1}
                        maxZoom={4}
                        leftBoundary={0}
                        topBoundary={0}
                      >
                        <img
                          src={item}
                          width="100%"
                          height="auto"
                          alt={photoCode}
                          className="imgId"
                        />
                      </PrismaZoom>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="modal-dialog false modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Support View</h4>
            </div>
            <div className="modal-body">
              <div className="group-false">
                <span>I think the picture doesn't exist yet !!!!!!!</span>
                <img src="/img/closeEnough.png" alt=""></img>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
  return (
    <div className={`modal image-support show-modal`} id="myModal">
      {showImg()}
    </div>
  );
};

export default ModalImageFull;
