import { connect } from 'react-redux';
import React, { useState, useEffect, useCallback } from 'react';
import { InspectionListTable } from './components';
import debounce from 'lodash/debounce';
import {
  getInspectionsLeft,
  getInspectionsRight,
  getActiveCages,
  putInspectionCage,
  inspectInspection,
  checkInspection,
  adminSetStandard,
  manage,
} from '../operations';
import ModalSetCage from './components/partials/ModalSetCage';
import Pagination from '../../../components/common/Pagination';
import ModalMD from './components/partials/ModalMD';
const InspectionList = (props) => {
  const { auth, inspection } = props;
  const { left, right, cages } = inspection;
  const leftSettings = left && left.settings ? left.settings : {};
  const rightSettings = right && right.settings ? right.settings : {};
  let [limitLeft, setlimitLeft] = useState(3);
  let [pageLeft, setpageLeft] = useState(1);
  let [limitRight, setlimitRight] = useState(10);
  let [pageRight, setpageRight] = useState(1);
  const dataLeft = left && left.list ? left.list : false;
  const dataRight = right && right.list ? right.list : false;
  const pagesLeft = dataLeft && dataLeft.pages ? dataLeft.pages : 1;
  const countLeft = dataLeft && dataLeft.count ? dataLeft.count : 1;
  const pagesRight = dataRight && dataRight.pages ? dataRight.pages : 1;
  const countRight = dataRight && dataRight.count ? dataRight.count : 1;
  const [filtersLeft, setfiltersLeft] = useState({
    group: 'DEFAULT',
  });
  const [filtersRight, setfiltersRight] = useState({
    group: 'AMZ',
  });
  const [autoRefresh, setautoRefresh] = useState(true);
  const [showModalSetCage, setshowModalSetCage] = useState(false);
  const [dataItem, setdataItem] = useState({});
  const [modalConfirm, setmodalConfirm] = useState(false);
  const [dataUpdate, setdataUpdate] = useState({});
  useEffect(() => {
    const timer = setInterval(() => {
      if (autoRefresh) {
        props.getInspectionsLeft(
          {
            page: pageLeft,
            limit: limitLeft,
            filters: filtersLeft,
          },
          false,
        );
        props.getInspectionsRight(
          {
            page: pageRight,
            limit: limitRight,
            filters: filtersRight,
          },
          false,
        );
      }
    }, 6000);
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line
  }, [
    limitLeft,
    pageLeft,
    filtersLeft,
    limitRight,
    pageRight,
    filtersRight,
    autoRefresh,
  ]);
  const onChangeList = (e, side, type) => {
    const filter = side === 'left' ? filtersLeft : filtersRight;
    let listFilter = [];
    if (type === 'status') {
      listFilter =
        filter.status && filter.status.length > 0 ? filter.status : [];
    }
    if (type === 'level') {
      listFilter =
        filter.levels && filter.levels.length > 0 ? filter.levels : [];
    }
    const checkIndexExists = listFilter.findIndex(
      (item) => item === e.target.value,
    );
    if (checkIndexExists !== -1) {
      listFilter.splice(checkIndexExists, 1);
    } else {
      listFilter.push(e.target.value);
    }
    if (type === 'status') {
      filter.status = listFilter;
    }
    if (type === 'level') {
      filter.levels = listFilter;
    }

    if (side === 'left') {
      setfiltersLeft(filter);
      props.getInspectionsLeft({
        limit: limitLeft,
        filters: filter,
      });
    }
    if (side === 'right') {
      setfiltersRight(filter);
      props.getInspectionsRight({
        limit: limitRight,
        filters: filter,
      });
    }
  };
  const onChange = (e, side) => {
    const filter = side === 'left' ? filtersLeft : filtersRight;
    if (e.target.name === 'sameVin') {
      if (filter[e.target.name] === 'on') {
        filter[e.target.name] = 'off';
      } else {
        filter[e.target.name] = e.target.value;
      }
    } else {
      filter[e.target.name] = e.target.value;
    }

    if (side === 'left') {
      setfiltersLeft(filter);
      handleSearch(filter, 'left');
    }
    if (side === 'right') {
      setfiltersRight(filter);
      handleSearch(filter, 'right');
    }
  };
  const handleSearch = useCallback(
    debounce((value, position) => {
      if (position === 'left') {
        props.getInspectionsLeft({
          limit: limitLeft,
          filters: value,
        });
      }
      if (position === 'right') {
        props.getInspectionsRight({
          limit: limitRight,
          filters: value,
        });
      }
    }, 1500),
    [],
  );
  const onPageChange = (e, side, page) => {
    e.preventDefault();
    if (e.target.getAttribute('data-value')) {
      limitLeft = parseInt(
        e.target.getAttribute('data-value') || limitLeft,
        10,
      );
      limitRight = parseInt(
        e.target.getAttribute('data-value') || limitRight,
        10,
      );
    }

    if ((page && page >= 1) || e.target.value) {
      pageLeft = parseInt(e.target.value || page, 10);
      pageRight = parseInt(e.target.value || page, 10);
    }
    if (side === 'left') {
      props.getInspectionsLeft({
        page: pageLeft,
        limit: limitLeft,
        filters: filtersLeft,
      });
      setpageLeft(pageLeft);
      setlimitLeft(limitLeft);
    }
    if (side === 'right') {
      props.getInspectionsRight({
        page: pageRight,
        limit: limitRight,
        filters: filtersRight,
      });
      setpageRight(pageRight);
      setlimitRight(limitRight);
    }
  };
  const setModalCage = (data) => {
    setshowModalSetCage(true);
    setautoRefresh(false);
    setdataItem(data);
  };
  const inspectInspection = (sessionID, action) => {
    const data = {};
    data['sessionID'] = sessionID;
    data['action'] = action;
    props.inspectInspection(data);
  };
  const checkInspection = (sessionID, action) => {
    const data = {};
    data['sessionID'] = sessionID;
    data['action'] = action;
    props.checkInspection(data);
  };
  const manage = (sessionID, action) => {
    const data = {};
    data['sessionID'] = sessionID;
    data['action'] = action;
    props.manage(data);
  };
  const showModalConfirm = (sessionID, standard) => {
    const data = {};
    data['sessionID'] = sessionID;
    data['standard'] = !standard;
    setdataUpdate(data);
    setmodalConfirm(true);
  };
  const adminSetStandard = () => {
    setmodalConfirm(false);
    props.adminSetStandard(dataUpdate);
  };
  return (
    <div id="Inspection_list">
      <div className="container-fluid">
        <div
          className="header d-flex text-head mt-2 mb-3"
          style={{
            padding: '8px 0',
            alignItems: 'center',
            background: '#321640',
            color: '#fff',
            borderRadius: '5px',
          }}
        >
          <div style={{ width: '6%', padding: '0.3rem', paddingLeft: '10px' }}>
            Group / Theme
          </div>
          <div style={{ width: '8%', padding: '0.3rem', paddingLeft: '10px' }}>
            Manager
          </div>
          <div style={{ width: '8%', padding: '0.3rem' }} data-key="status">
            QA / Inspector
          </div>
          <div style={{ width: '9%', padding: '0.3rem' }} data-key="status">
            Status
          </div>
          <div
            style={{ width: '12%', padding: '0.3rem' }}
            data-key="inspectionID"
          >
            ID
          </div>
          <div style={{ width: '8%', padding: '0.3rem' }}> Photos </div>
          <div style={{ width: '15%', padding: '0.3rem' }}> Vehicle </div>
          <div style={{ width: '15%', padding: '0.3rem' }}> Detected Cage </div>
          <div style={{ width: '11%', padding: '0.3rem' }}> Date/Time </div>
          <div style={{ width: '8%', padding: '0.3rem' }}> Duration </div>
        </div>
        <div style={{ position: 'relative' }}>
          <div
            className="list_data_left"
            style={{
              marginTop: '10px',
              height: '320px',
              overflow: 'scroll',
            }}
          >
            <InspectionListTable
              data={dataLeft && dataLeft.data.length > 0 ? dataLeft.data : []}
              filters={filtersLeft}
              onChangeList={onChangeList}
              onChange={onChange}
              settings={leftSettings}
              auth={auth}
              manage={manage}
              checkInspection={checkInspection}
              inspectInspection={inspectInspection}
              showModalConfirm={showModalConfirm}
              adminSetStandard={adminSetStandard}
              setModalCage={setModalCage}
              side={'left'}
            />
          </div>
          <div
            style={{
              position: 'absolute',
              right: '20px',
              bottom: '-55px',
              zIndex: '99',
            }}
          >
            <Pagination
              size={limitLeft}
              records={countLeft}
              page={pageLeft}
              pages={pagesLeft}
              action={onPageChange}
              side={'left'}
            />
          </div>
        </div>
        <div className="list_data_right" style={{ marginTop: '50px' }}>
          <div
            style={{
              position: 'fixed',
              right: '20px',
              bottom: '5px',
              zIndex: '9999',
            }}
          >
            <Pagination
              size={limitRight}
              records={countRight}
              page={pageRight}
              pages={pagesRight}
              action={onPageChange}
              side={'right'}
            />
          </div>
          <InspectionListTable
            onChangeList={onChangeList}
            data={dataRight && dataRight.data.length > 0 ? dataRight.data : []}
            filters={filtersRight}
            onChange={onChange}
            settings={rightSettings}
            auth={auth}
            inspectInspection={inspectInspection}
            checkInspection={checkInspection}
            manage={manage}
            showModalConfirm={showModalConfirm}
            setModalCage={setModalCage}
            side={'right'}
          />
        </div>
        {showModalSetCage ? (
          <ModalSetCage
            dataItem={dataItem}
            hideModal={() => setshowModalSetCage(false)}
            getActiveCages={props.getActiveCages}
            cages={cages}
            putInspectionCage={props.putInspectionCage}
            setautoRefresh={() => setautoRefresh(true)}
          />
        ) : null}
        <ModalMD
          show={modalConfirm}
          title="Set Standard"
          body={
            <div className="main-content">
              <div className="form-group">
                <div className="text-center">
                  <span className="text-head" style={{ fontSize: '20px' }}>
                    {`Are u sure , that u want to ${
                      dataUpdate.standard ? 'use' : 'remove'
                    } this ${dataUpdate.sessionID} ?`}
                  </span>
                </div>
              </div>
              <div className="btn-group float-right">
                <button className="btn btn-warning" onClick={adminSetStandard}>
                  <span className="text-bold" style={{ fontSize: '14px' }}>
                    Yes
                  </span>
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => setmodalConfirm(false)}
                >
                  <span className="text-bold" style={{ fontSize: '14px' }}>
                    No
                  </span>
                </button>
              </div>
            </div>
          }
          sizeModal="modal-md"
          handleClose={() => setmodalConfirm(false)}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  inspection: state.inspection,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getInspectionsLeft,
  getInspectionsRight,
  getActiveCages,
  putInspectionCage,
  inspectInspection,
  adminSetStandard,
  checkInspection,
  manage,
})(InspectionList);
