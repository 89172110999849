import React from 'react';

const TitleInspect = (props) => {
  const { taskSettings, taskInspect } = props;
  const title = taskSettings?.description ?? null;
  const photoCode = taskInspect?.photoCode ?? null;

  return (
    <div className="group-title">
      <div className="title-inspect mb-1 mt-3">
        <div className="tag-title text-head text-uppercase ">
          <div
            style={{ fontSize: '19px' }}
            className="badge badge-success d-inline number mr-1"
          >
            {photoCode}
          </div>
          <div
            style={{ fontSize: '19px' }}
            className="badge badge-primary title d-inline mr-1"
          >
            {title}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TitleInspect;
