import React, { useState } from 'react';
import ProgressTimeline from './partials/ProgressTimeline/';
import { connect } from 'react-redux';
import {
  rejectTask,
  reportImage,
  acceptTask,
  getProcessPhoto,
  validatePhoto,
  vehicleData,
} from '../../operations';
import { resetAll } from '../../../inspect/operations';
import _orderBy from 'lodash/orderBy';
import AgentOut from './partials/AgentOut';
import AdminAction from './partials/AdminAction';
import Progress from './partials/Progress';
import ModalMD from './partials/ModalMD';
import SVG from 'react-inlinesvg';
const InspectionViewInspect = (props) => {
  const [showInfo, setshowInfo] = useState('progress');
  const [showValidateSuccess, setshowValidateSuccess] = useState(false);
  const {
    auth,
    inspection,
    tasks,
    show,
    photosSetting,
    adminAction,
    qcPassed,
  } = props;

  const user = auth.user;
  const timezone =
    user && user.options && user.options.timezone
      ? user.options.timezone
      : 'Asia/Ho_Chi_Minh';
  let inspTasks = _orderBy(tasks, ['photoCode'], ['asc']);
  const handleCloseValidate = () => {
    setshowValidateSuccess(false);
    if (['AGENT', 'QA'].includes(user.role)) {
      const currentDomain = window.location.origin;
      const newPath = `/inspect`;
      window.location.href = currentDomain + newPath;
    }
  };
  return (
    <div id="Inspection_view_progress" className="card">
      <div className="card-header" id="Inspection_view_progress_head">
        <span
          className={`menu ${showInfo === 'progress' ? 'active' : ''}`}
          onClick={() => {
            setshowInfo('progress');
          }}
        >
          Progress
        </span>
        {[
          'VIN-CHECKING',
          'VIN-CONFIRM',
          'PROCESSING',
          'INSPECTING',
          'CHECKING',
          'VALIDATING',
          'NEED-CONFIRM',
          'FINISHED',
          'INSPECTED',
          'CONFIRMED',
          'COMPLETED',
        ].includes(inspection.status) && (
          <span
            className={`menu ${showInfo === 'timeline' ? 'active' : ''}`}
            onClick={() => {
              setshowInfo('timeline');
            }}
          >
            Timeline
          </span>
        )}
        {['TRAINER', 'SUPER'].includes(user.role) && (
          <span
            className={`menu ${showInfo === 'timeout' ? 'active' : ''}`}
            onClick={() => {
              setshowInfo('timeout');
            }}
          >
            Timeout &amp; Kick
          </span>
        )}
        {['TRAINER', 'SUPER', 'ADMIN'].includes(user.role) && (
          <span
            className={`menu ${showInfo === 'adminAction' ? 'active' : ''}`}
            onClick={() => {
              setshowInfo('adminAction');
            }}
          >
            Action
          </span>
        )}
      </div>
      <div
        id="Inspection_view_progress_content"
        className={`collapse ${show ? 'show' : ''}`}
        aria-labelledby="Inspection_view_progress_head"
        data-parent="#Inspection_panels"
        style={{ padding: '10px' }}
      >
        {showInfo === 'progress' && (
          <Progress
            qcPassed={qcPassed}
            inspection={inspection}
            tasks={inspTasks}
            photosSetting={photosSetting}
            user={user}
            reportImage={props.reportImage}
            rejectTask={props.rejectTask}
            acceptTask={props.acceptTask}
            validatePhoto={props.validatePhoto}
            setshowValidateSuccess={() => setshowValidateSuccess(true)}
            resetInspect={props.resetAll}
            vehicleData={props.vehicleData}
          ></Progress>
        )}
        {showInfo === 'timeline' && (
          <ProgressTimeline
            inspection={inspection}
            tasks={inspTasks}
            timezone={timezone}
          ></ProgressTimeline>
        )}
        {showInfo === 'timeout' && (
          <AgentOut inspection={inspection} tasks={inspTasks} />
        )}
        {showInfo === 'adminAction' && (
          <AdminAction
            inspection={inspection}
            tasks={inspTasks}
            vehicle={inspection.vehicle || {}}
            adminAction={adminAction}
            timezone={timezone}
          />
        )}
        <ModalMD
          show={showValidateSuccess}
          title="Validate All Done"
          body={
            <div className="main-content">
              <div className="form-group">
                <div className="text-center">
                  <SVG src={'/img/login.svg'} uniquifyIDs={false}></SVG>
                  <span className="text-head" style={{ fontSize: '20px' }}>
                    You just completed the last view of this session
                  </span>
                </div>
              </div>
              <div className="btn-group float-right">
                <button
                  className="btn btn-success"
                  onClick={() => {
                    handleCloseValidate();
                  }}
                >
                  <span className="text-bold" style={{ fontSize: '14px' }}>
                    Yes
                  </span>
                </button>
              </div>
            </div>
          }
          sizeModal="modal-md"
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {
  rejectTask,
  reportImage,
  acceptTask,
  getProcessPhoto,
  resetAll,
  validatePhoto,
  vehicleData,
})(InspectionViewInspect);
